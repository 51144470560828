import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../utils';
import { changeCurrentSelectedBusiness } from '../actions';
import _ from 'lodash';
import { Gutter } from '../stylesheets/components';
import { Header, PoopMapContainer, BusinessShow } from '../components';

import kakaotalk_icon from "../assets/kakaotalk_icon.svg";
import telegram_icon from "../assets/telegram_icon.svg";
import phone_icon from "../assets/phone_icon.svg";
import email_icon from "../assets/email_icon.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import vent_01 from "../assets/vent_01.jpg";
import vent_02 from "../assets/vent_02.jpg";
import vent_03 from "../assets/vent_03.jpg";
import vent_04 from "../assets/vent_04.jpg";

import upcycling_01 from "../assets/upcycling_01.jpg";
import upcycling_02 from "../assets/upcycling_02.jpg";
import upcycling_03 from "../assets/upcycling_03.jpg";

import euljiro_logo_top from "../assets/euljiro_logo_top.svg";
import gsap from 'gsap';
import media from '../stylesheets/media';


const Fragment = React.Fragment;
const Title = styled.h1`
  padding: 20px 22px;
  font-weight: 900;
  line-height:1.2;
  font-size:6em;

  ${media.mobileSmall`
    font-size: 4em;
  `}
`;

const TitleLogo = styled.h1`
  text-align: center;
  padding: 10px 0;

  img {
    ${media.mobileSmall`
      width: 100%;
    `}
  }
`;

const ContactBar = styled.div`
  padding: 20px 22px 15px 22px;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  display: flex;
  margin: 20px 0;

  ${media.mobileSmall`
    display: block;
  `}

`
const ContactBarColumn = styled.div`
  width: 400px;

  ${media.mobileSmall`
    width: 100%;
  `}
`
const ContactArea = styled.div`
  display: flex;
  margin-bottom: 3px;


  ${media.mobileSmall`
    margin-bottom: 8px;
  `}
`;

const ContactIcon = styled.div`
  margin-right: 5px;
  margin-top: 5px;
`
const ContactLabel = styled.div`
  font-size: 1.2em;
  line-height: 1.6;
`
const DescColumnArea = styled.div`
  display: flex;
  padding: 20px 22px;

  ${media.mobileSmall`
    display: block;
  `}
`
const DescColumn = styled.div`
  width: 600px;
  margin-right: 50px;
  p {
    font-size: 1.2em;
    line-height: 1.7;
    margin-bottom: 30px;
  }

  &:last-child {
    margin: 0;
  }

  ${media.mobileSmall`
    width: 100%;
  `}
`;

const DescTitle = styled.div`
  font-weight: 900;
  font-size: 1.2em;
  margin-bottom: 10px;
`

const Subtitle = styled.div`
  margin: 0;
  font-size:1.2em;
  margin-left: 40px;
  margin-bottom: 5px;
`;

const ventilatorPhotos = [
  {photo: vent_01},
  {photo: vent_02},
  {photo: vent_03},
  {photo: vent_04}
]


const recyclingPhotos = [
  {photo: upcycling_01},
  {photo: upcycling_02},
  {photo: upcycling_03}
]

const PoopMapArea = styled.div`
  position: relative;
  width: calc(100% - 40px);
  margin: 0 auto;
`

const ProductBtn = styled.a`
  display: inline-block;
  border: 1px solid white;
  color: white;
  background: transparent;
  border-radius: 30px;
  padding: 7px 20px;
  font-size: 1.0em;
  line-height:1.2;
  margin-right: 15px;
  
  &.last-child {
    margin: 0;
  }
`;

const ProductBtnArea = styled.div`
  
`

class TechAgency extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
   
    gsap.to((document.scrollingElement || document.documentElement), 1, { ease: "power3.inOut", scrollTop: 0});
  }

  componentWillUnmount() {

    this.props.dispatch(changeCurrentSelectedBusiness(null));
  }

  render() {
    let { currentSelectedBusiness } = this.props;
    return (
      <Fragment>
        <a name="top"></a>
        <Header />
        <TitleLogo>
          <img src={euljiro_logo_top} alt="청계천 을지로 기술 유통 중개소" />
        </TitleLogo>
        <ContactBar>
          <ContactBarColumn>
            <ContactArea>
              <ContactIcon> 
                <img src={email_icon} alt="이메일 아이콘"/>
              </ContactIcon>
              <ContactLabel>
                cheongyecheon@gmail.com<br/>
                socialcapital.seoul@gmail.com 
              </ContactLabel>
            </ContactArea>
          </ContactBarColumn>

          <ContactBarColumn>
            <ContactArea>
              <ContactIcon>
                <img src={kakaotalk_icon} alt="카카오톡 아이콘"/>
              </ContactIcon>
              <ContactLabel> 
                카카오톡 listentothecity  
              </ContactLabel>
            </ContactArea>

            <ContactArea>
              <ContactIcon> 
                <img src={telegram_icon} alt="텔레그램 아이콘"/>
              </ContactIcon>
              <ContactLabel>
                텔레그램 listentothecity
              </ContactLabel>
            </ContactArea>
          </ContactBarColumn>

          <ContactBarColumn>
            <ContactArea>
              <ContactIcon>
                <img src={phone_icon} alt="전화기 아이콘"/>
              </ContactIcon>
              <ContactLabel>
                010-4297-8652
              </ContactLabel>
            </ContactArea>

            <ContactArea>
              <ContactIcon>
                <img src={kakaotalk_icon} alt="카카오톡 아이콘"/>
              </ContactIcon>
              <ContactLabel>
                카톡채널 cheongye
              </ContactLabel>
            </ContactArea>
          </ContactBarColumn>


         
        </ContactBar>
        
        <PoopMapArea>
          {
            currentSelectedBusiness ? 
            <BusinessShow mode="poop" /> : null
          }
          <PoopMapContainer />
        </PoopMapArea>
       
        <DescColumnArea>
          <DescColumn>
            <DescTitle>
              &lt;청계천을지로 기술·유통 중개소&gt; 란?
            </DescTitle>

            <p>
              다양한 창의성을 가진 이용자들이 전국 최고 청계천 을지로의 기술자 및 물품들을 찾고 쉽게 이용할 수 있도록 연결시켜 드리는 매개 서비스이다. 청계천을지로보존연대는 기술과 창의력의 만남 그리고 만드는 것의 기쁨을 지지하며 청계천 을지로 산업생태계를 유지하고 발전시키고자 한다. 청계천-을지로 지역의 경쟁력은 비단 기술력 뿐만 아니라 기술자들이 서로 알려주고 나눠주면서 성장해온 도심 산업 유통 커뮤니티이다. 이 커뮤니티에 창작자들이 쉽게 진입할 수 있도록 청계천을지로 기술·유통 중개소 활동가들이 함께 하고자 한다. 
            </p>
            <p>
              
              이 서비스는 청계천-을지로 지역의 젠트리피케이션을 방지하고 더 나은 산업생태계를 만들고자 애쓰고 있는 청계천을지로보존연대에 기반하고 있다. 만들고 싶은 기계가 있다면, 만들고 싶은 작품이 있다면, 수리하고 싶은 기계가 있다면, 필요한 특수부품이 있다면 중개 의뢰가 가능하다. 

            </p>

            <DescTitle>
              유통 및 기술장인들의 전문 분야 
            </DescTitle>
            <p>
              1. 의료 기계 및 관련 부품 <br/>
              2. 실험기계 및 모든 기계 부품 및 제작 (ex. 쿠에트테일러, IoT 기술 접목 기계, 등고선 측정기, 발열기, 인쇄기, 사진기 부속 등)<br/>
              3. 예술작품 제작 및 재료 (ex. 철 용접, 비철용접, 조명, 주물 등)<br/>
              4. 악기류 제작 및 재료<br/>
              5. 무엇이든 물어보세요. 특수 소재, 부품 구입 문의, 소량부터 대량생산까지 모두 가능합니다.
            </p>
          </DescColumn>
          <DescColumn>
            <DescTitle>
              이용 방법
            </DescTitle>
            <p>
              ① <a href="https://fsm.network/" target="_blank">'똥본위화폐'</a> 홈페이지에 가입<br/>
              ② 이용하고 싶은 업체의 게시물을 고른 뒤, '구매요청'을 클릭<br/>
              ③ '구매요청'후 의뢰내용을 채팅 혹은 연락처로 전달
            </p>

            <p>
              ※ 참고: 의뢰내용(안)<br/>
              - 구매하고 싶은 물품에 대한 상담<br/>
              - 이용 희망 사항(제작, 수리 등) <br/>
              - 제작하고 싶은 제품 사항(기능, 모양, 재질 등)<br/>
              - 제작 기한 
            </p>

            <p>
              ④ 기술유통 중개소 담당자가 이용자의 의뢰내용을 해당 유통 및 기술업체에게 전달<br/>
              ⑤ 유통 및 기술업체가 의뢰내용을 확인 후, 의뢰자와 구체적인 상담을 진행
            </p>
          </DescColumn>
        </DescColumnArea>

        <Gutter h={50} />
        <Title>
          오픈소스
        </Title>
        <Gutter h={20} />
        <DescColumnArea>
          <DescColumn>
            <DescTitle>
              DIY 환풍기 공기 정화 필터
            </DescTitle>

            <Subtitle>
              제작배경
            </Subtitle>
            <p>
              청계천-을지로 작업장에서 생성되는 미세먼지는 여과없이 배출되는 경우가 많다.  
              환풍기에 DIY 공기정화 필터를 씌우면 미세먼지가 걸러져 외부로 배출된다.
            </p>

            <Subtitle>
              이용방법
            </Subtitle>
            <p>
              대부분의 환풍기는 일반적으로 30×30cm이다. 도면은 이 사이즈를 기본으로 해서 제작되었다. 공유된 도면을 기반으로 하드보드지를 자르고 접으면 쉽게 환풍기 케이스를 제작할 수 있다. 작업장 내부 쪽에서 환풍기에 덧씌우면 간편하게 사용이 가능하다.
            </p>
            <Gutter h={10} />
            <ProductBtn href="/vent_diagram.pdf" target="_blank">도면 다운로드</ProductBtn>
          </DescColumn>
          <DescColumn>
            <Carousel dynamicHeight={true} showThumbs={false}>
              {
                _.map(ventilatorPhotos, (photo, idx) => {
                  return (
                    <img src={photo.photo} key={idx} alt="image" />
                  )
                })
              }
              </Carousel>
          </DescColumn>
        </DescColumnArea>

        <DescColumnArea>
          <DescColumn>
           
            <DescTitle>
              플라스틱 업사이클링 기계
            </DescTitle>
            <Subtitle>
              제작배경
            </Subtitle>
            <p>
              하루에도 엄청난 양의 재료들을 깎고 잘라 물건을 만드는 공장들. 청계천-을지로 일대에서 제작되는 가공품의 원료는 철, 비금속, 플라스틱 등으로 다양하다. 이 중에서 유일하게 자체적인 수거 시스템으로 재활용되지 않는 플라스틱 쓰레기를 모아 업사이클링하기 위해 제작되었다. 
            </p>

            <Subtitle>
              원리 및 방법
            </Subtitle>
            <p>
              프레셔스 플라스틱(precious plastic)의 오픈소스 디자인을 참고해, 청계천-을지로 기술자들과 함께 좀 더 발전된 기능을 갖춘 파쇄기와 사출기를 제작했다. 파쇄기에서 플라스틱을 잘게 칩(chip)으로 만들면 사출기에서 이를 고온으로 녹여 분사한다. 사출기 입구에 여러 모양의 금형을 덧대어 다양한 업사이클링 물건을 만든다.
            </p>
            <ProductBtnArea>
              <ProductBtn href="/upcycling_shredder_diagram.jpg" target="_blank">파쇄기 도면 다운로드</ProductBtn>
              <ProductBtn href="/upcycling_molding_diagram.jpg" target="_blank">시출기 도면 다운로드</ProductBtn>
            </ProductBtnArea>
            
          </DescColumn>
          <DescColumn>
            <Carousel dynamicHeight={true} showThumbs={false}>
              {
                _.map(recyclingPhotos, (photo, idx) => {
                  return (
                    <img src={photo.photo} key={idx} alt="image" />
                  )
                })
              }
              </Carousel>
          </DescColumn>
        </DescColumnArea>


      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    currentSelectedBusiness: state.currentSelectedBusiness
  }
}

export default withRouter(connect(mapStateToProps)(TechAgency));