import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { changeLightBoxPhoto } from '../actions';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const Container = styled.div`
  margin-top: 20px;
`;

const OrgPhotoDiv = styled.div`
  cursor: pointer;
`;

class OrgPhotos extends Component {
  
  handleClick(photo){
    this.props.dispatch(changeLightBoxPhoto(photo));
  }

  render() {
    let { currentOrgPhotos } = this.props;
    return (
      <Container>
         <Carousel dynamicHeight={true} showThumbs={false}>

          {
            _.map(currentOrgPhotos, (photo, idx) => {
              return (
                <OrgPhotoDiv key={photo.thumb} onClick={this.handleClick.bind(this, photo)}>
                  <img src={photo.thumb}  alt="image" />
                </OrgPhotoDiv>
              )
            })
          }
         </Carousel>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentOrgPhotos: state.currentOrgPhotos
  }
}

export default connect(mapStateToProps)(OrgPhotos);