import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setColor } from '../utils';
import styled from 'styled-components';
import { changeCurrentSelectedBusiness } from '../actions';
import _ from 'lodash';
import { BSNetworkArea,
  BSNetworkTitle,
  BSNode,
  BSNodeName } from '../stylesheets/components';
import media from '../stylesheets/media';
import banner from "../assets/banner.png";

const Fragment = React.Fragment;
const BSContainer = styled.div`
  margin-left: 20px;
  width: 350px;
  background: #000000;
  border: 1px solid #3A3A3A;
  padding: 20px;
  position: sticky;
  top: 64px;
  z-index: 5;
  ${media.mobileSmall`
    top: 52px;
    width: calc(100vw - 82px);
  `}
`;

const BannerImg = styled.img`
  width: 220px;
  border: 1px solid #222;
`;

const BSTitleArea = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #404040;
  padding-bottom: 10px;
  margin-bottom: 10px;
`


const BSTitle = styled.h3`
  font-weight: 900;
`
const BSCategory = styled.div`
  display:flex;
  align-items: center;
`;
const BSCategoryLabel = styled.div`
  margin-right: 5px;
`
const BSCategoryCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: white;
`

const BSDescArea = styled.div``
const BSLabel = styled.div`
  margin-bottom: 5px;
  color: #777;
  
`
const BSValue = styled.div`
  font-weight: 900;
  margin-bottom: 10px;
`


class BusinessShow extends Component {
  getNetworks(){
    let { ecosystemsData, currentSelectedBusiness } = this.props;
    
    let networks = _.filter(ecosystemsData, es => {
      return es.sid === currentSelectedBusiness.node_id || es.tid === currentSelectedBusiness.node_id;
    })

    return networks;

  }

  handleNodeClick(id) {
    let { businessesData } = this.props;

    let clickedBusiness = _.find(businessesData, b => b.node_id === id);
    this.props.dispatch(changeCurrentSelectedBusiness(clickedBusiness));
  }


  render() {
    let { currentSelectedBusiness } = this.props;
    let networks = this.getNetworks();
    
    let poopStyleOverride = this.props.mode === "poop" ? 
      {
        margin: 0,
        position: "absolute",
        left: 10,
        top: 10
      } : {

      };

    return (
      <BSContainer style={poopStyleOverride}>
        <BSTitleArea>
          <BSTitle>
            {currentSelectedBusiness.name}
          </BSTitle>
          <BSCategory>
            <BSCategoryLabel>
              { currentSelectedBusiness.category}
            </BSCategoryLabel>
            <BSCategoryCircle 
              style={{ backgroundColor: setColor(currentSelectedBusiness.category, "hex") }}
            />
          </BSCategory>
        </BSTitleArea>
        <BSDescArea>
          {
            currentSelectedBusiness.address ? 
            <Fragment>
              <BSLabel>
                주소
              </BSLabel>
              <BSValue>
                { currentSelectedBusiness.address }
              </BSValue>
            </Fragment> : null
          }

          {
            currentSelectedBusiness.poopcoin_url ? 
            <Fragment>
              <BSLabel>
              똥본위 화폐 링크
              </BSLabel>
              <BSValue>
                <a href={currentSelectedBusiness.poopcoin_url} target="_blank"><BannerImg src={banner} alt="중개소 링크 배너" /></a><br/>
                <a href={currentSelectedBusiness.poopcoin_url} target="_blank">바로가기</a>
              </BSValue>
            </Fragment> : null
          }

          {
            networks.length > 0 ?
            <BSNetworkArea>
              <BSNetworkTitle>
                거래 업체 <b>{networks.length}</b>
              </BSNetworkTitle>

              {
                _.map(networks, network => {
                  let nodeType = network.sid === currentSelectedBusiness.node_id ? "t" : "s";

                  return (
                    <BSNode key={network.id} onClick={this.handleNodeClick.bind(this, network[`${nodeType}id`])}>
                      <BSNodeName>
                        { network[`${nodeType}name`]}
                      </BSNodeName>
                      <BSCategory>
                        <BSCategoryLabel>
                          { network[`${nodeType}category`]}
                        </BSCategoryLabel>
                        <BSCategoryCircle 
                          style={{ backgroundColor: setColor(network[`${nodeType}category`], "hex") }}
                        />
                      </BSCategory>
                    </BSNode>
                  )
                })
              }
            </BSNetworkArea> : null
          }
        </BSDescArea>
      </BSContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentSelectedBusiness: state.currentSelectedBusiness,
    ecosystemsData: state.ecosystemsData,
    businessesData: state.businessesData
  }
}

export default connect(mapStateToProps)(BusinessShow);

