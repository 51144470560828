import styled from 'styled-components';
import mixins from './mixins';
import { Link } from 'react-router-dom';
import media from "./media";

const FlexSpaceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


const FlexSpaceWrapperCenter = styled(FlexSpaceWrapper)`
  align-items: center;
`;

const FormBorderWrapper = styled.div`
  border: 1px solid white;
`;

const MobileCaveat = styled.div`
  color: #666;
  font-size:12px;
  font-weight: 400;
  letter-spacing: .0125em;
  display: none;
  padding: 5px 0;
  
  ${media.mobileLarge`
    display: block;
  `}
`;

const TextFieldArea = styled.div`

  label {
    color: white;
    display: block;
    padding: 13px;
    border-bottom: 1px solid white;
  }
  textarea {
    border: none;
    padding: 13px;
    font-family: "Gordita";
    font-size: 1.0em;
    width: calc(100% - 26px);
    height: 150px;
    color: white;
    background: transparent;

    ::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  span.error {
    color: white;
    font-size: 0.8em;
  }
  
  border-bottom: 1px solid white;

`;


const FormField = styled.div`
  border-bottom: 1px solid white;
  padding: 13px;
  display: flex;
  justify-content: space-between;

  label {
    color: white;
    width: calc(40% - 10px);
  }

  div.right {
    width: calc(60% - 10px);
    font-size: 1.0em;
    color: white;
    background:transparent;
  }
  

  span.error {
    color: white;
    font-size: 0.8em;

  }

  input {
    border: none;
    width: 330px;
    font-size: 1.0em;
    color: white;
    background:transparent;
    ::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }

    ${media.mobileLarge`
      width: calc(60% - 10px);
    `}
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      opacity: 0.5; /* Firefox */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      opacity: 0.5; /* Firefox */
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;



const ButtonA = styled.a`
  display: inline-block;
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  padding: 13px 20px 8px;
  font-size: 1.4em;

  ${media.mobileLarge`
    font-size:0.9em;
  `}
`;

const MobileMapTransparentBlocker = styled.div`
  
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index:5;
  height: 100%;
  display: none;

  ${media.mobileLarge`
    display: block;
  `}
`

const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-right: 5px;
  }

  ${media.padLandscape`
    display: block;
  `}

  div {

  ${media.padLandscape`
    margin-bottom: 10px;
  `}
  }
`;

const SearchLayout = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const MenuTitle = styled.h2`
  font-family: "Playfair Display";
  font-weight: 600;
  line-height: 1;
  font-size: 1.8571em;
`;



const BlackBtn = styled.button`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Gordita';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #FFF;
    color: white;
    border: 2px solid #444;
  }

  &:disabled {
    background: #AAA;
    color: #BBB;
  }
`;


const SmallTitle = styled.div`
  font-family: "Playfair Display";
  font-size: 1.1em;
  font-weight: 600;
`;


const WhiteBtnA = styled.a`
  background: #FFF;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Gordita';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: white;
    border: 2px solid #444;
  }
`;



const BlackBtnInput = styled.input`
  background: #000000;
  border-radius: 5px;
  border:none;
  padding: 10px;
  font-family: 'Gordita';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: white;
    color: black;
    border: none;
  }

  &.search {
    width: calc(30% - 20px);
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.4);
  }
`;



const BlackBtnLink = styled(Link)`
  background: #000000;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px;
  display: inline-block;
  font-family: 'Gordita';
  font-size:1.2em;
  font-weight: 700;
  color: white;
  transform-origin: center;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: #AAA;
    color: white;
    border: 2px solid #444;
  }

  &.search {
    width: calc(30% - 20px);
  }
  &:disabled {
    background: #AAA;
    color: #888;
    border: 2px solid #AAA;
  }
`;




const Gutter = styled.div`
  height: ${props => props.h}px;
`;

const TextInput = styled.input`
  font-family: "Gordita";
  font-size: 1.286em;
  color: white;
  padding: 15px 10px;
  border: 2px solid white;
  border-radius: 5px;

  &.search {
    width: calc(70% - 20px);
  }
`;



const Container = styled.div`
  width: calc(100vw - 80px);
  padding: 0 40px;
  margin: 0 auto;
`;


const WhiteBtn = styled.button`
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 10px;
  font-family: 'Gordita';
  font-size:1.2em;
  font-weight: 700;
  transform-origin: center;
  margin-right: 10px;
  max-width: 200px;
  cursor:pointer;
  text-align: center;
  font-size:1.1em;

  &:hover {
    opacity: 1;
    background: white;
    color: white;
    border: 1px solid #444;
  }
`;

const PageTitle = styled.h2`

  font-family: "Playfair Display";
  font-size: 3.571em;
  line-height: 1.0;
  font-weight: 600;
  color: white;
`;


const DescContainer = styled.div`
`
const DescTitle = styled.div`
  font-weight: 600;
  line-height: 1.4;
  font-size: 1.2em;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 40px;

  ${media.mobileSmall`
    width: calc(100% - 40px);
  `}
`

const DescBigQuote = styled.div`
  font-weight: 900;
  line-height: 1.4;
  text-indent: -0.5em;
  font-size: 3.928em;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 40px;

  ${media.mobileLarge`
    width: calc(100% - 100px);
    font-size: 3.5em;
  `}

  ${media.mobileSmall`
    width: calc(100% - 40px);
    font-size: 2em;
  `}

`;


const DescParagraph = styled.div`
  font-size: 1.285em;
  letter-spacing: .0125em;
  line-height: 1.75;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 30px;
  word-break: keep-all;

  ${media.mobileSmall`
    font-size: 1.1em;
    width: calc(100% - 40px);
  `}

  a {
    text-decoration: underline;
    text-decoration-color:rgba(255, 255, 255, 0.3);
  }
`


const Quoteby = styled.div`
  color: #666;
  font-size:12px;
  font-weight: 400;
  letter-spacing: .0125em;


  a {
    color: #666;
  }
`

const Caveat = styled.div`
  color: #666;
  font-size:12px;
  font-weight: 400;
  letter-spacing: .0125em;

  @media (max-width: 414px) {
    font-size: 0.9em;
  }

`

const BSNetworkArea = styled.div`
  margin-top: 20px;
`;

const BSNetworkTitle = styled.div`

  color: #929292;
  b {
    font-weight: 900;
    color: white;
  }

  border-bottom: 1px solid #444;
  padding-bottom: 7px;
  /* margin-bottom: 7px; */

`

const BSNode = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #222;
  padding-bottom: 7px;
  padding-top: 7px;
  /* margin-bottom: 7px; */
  cursor: pointer;
  &:hover {
    background-color: rgba(22, 22, 22, 0.5);
  }
`

const BSNodeName = styled.div`
  color: white;
  font-weight: 500;
`



export {
  DescContainer,
  DescTitle,
  DescParagraph,
  DescBigQuote,
  Gutter,
  Quoteby,
  BSNetworkArea,
  BSNetworkTitle,
  BSNode,
  BSNodeName,
  Caveat,
  MobileMapTransparentBlocker,
  MobileCaveat
};