import React, { Component, Fragment } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { IndustryNetworkMapContainer, BusinessShow, BusinessShowCloseBtn } from '../components';
import { DescContainer, DescBigQuote, DescTitle, DescParagraph, Gutter, Quoteby, Caveat, MobileCaveat } from '../stylesheets/components';
import { changeCurrentSelectedBusiness } from '../actions';
import "intersection-observer";
import scrollama from "scrollama";
import media from '../stylesheets/media';


const StickyContainer = styled.div`
  position:relative;
  margin-top: 50px;
  height: 10000px;
`;


const ScrollContainer = styled.div`
  margin-right: 50px;
  position:relative;
  width: 600px;
  left: 50%;
  transform: translate(-50%, 0);
`;


const ScrollLabel = styled.div`
  width: 392px;
  z-index: 20;
  position: absolute;
  left: 20px;
  /* transform: translate(-50%, 0); */

  

  @media (max-width: 414px) {
    width: calc(100vw - 40px);

  }
  

  @media (max-width: 414px) {
    width: calc(100vw - 40px);

  }

  img {


    @media (max-width: 414px) {
        width: 100%;
    }
  }

  div.back { 
    font-size: 1.2em;
    line-height: 1.6;
    color: white;
    background-color: black;
    border: 1px solid #3F3F3F;
    padding: 15px;

    @media (max-width: 414px) {
      font-size: 0.9em;
    }

    a {
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }

    b{
      font-variation-settings: "wght" 400;

    }
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

const IndustQuoteby = styled(Quoteby)`
  /* padding-left: 30px; */
  padding-top: 10px;
  text-indent: 0;
  ${media.mobileSmall`
    padding-top: 5px;
  `}
`;


class IndustryNetworkPart extends Component {
  constructor(props){
    super(props);
    this.state = {
      step: 0,
      scrollLabelzIndex: 11,
      touchInteractiveEnabled: false
    }
    this.zSetIntervalId = null;
  }
  initZIndex() {
    this.zSetIntervalId = setInterval(() => {
      this.setState({
        scrollLabelzIndex: Math.random() > 0.5 ? 11 : 12
      });
    }, 100)
  }
  componentWillUnmount(){
    clearInterval(this.zSetIntervalId);
  }

  componentDidMount(){
    // instantiate the scrollama
    const scroller = scrollama();

    // setup the instance, pass callback functions
    scroller
      .setup({
        step: ".step",
        progress: true,
        offset: 0.9
      })
      .onStepEnter(response => {
        this.setState({
          step: response.index
        });

        this.props.dispatch(changeCurrentSelectedBusiness(null));
      })
      .onStepExit(response => {
        // console.log(response);
        let finalStep = response.index;

        if (response.direction === "up") {
          finalStep = finalStep - 1;

          if (finalStep < 0) {
            finalStep = 0;
          }
          
          this.setState({
            step: finalStep
          });

        this.props.dispatch(changeCurrentSelectedBusiness(null));
        }
      })

    // setup resize event
    window.addEventListener("resize", scroller.resize);
  }


  handleTouchInteractiveEnabled(e){
    console.log(this.state.touchInteractiveEnabled);
    this.setState({
      touchInteractiveEnabled: !this.state.touchInteractiveEnabled
    })
  }

  render() {
    let { step } = this.state;
    let { windowHeight, currentSelectedBusiness, businessesData } = this.props;

    return (
      <Fragment>
        <a name="industry-network-part"></a>
        <DescContainer>
          <DescTitle>
            청계천 을지로 산업 유통 생태계<br/>
            Industry &amp; Trade Ecosystem of Cheongyecheon, Euljiro
          </DescTitle>
          <DescBigQuote>
            “청계천·을지로 제조산업단지의 특성은 제조와 유통이 결합한 구조, 수리업의 발달, 숙련 노동자 밀집, 긴밀한 협력 체계, 신속함과 저렴함, 맞춤 생산과 유연 생산, 지리적 요건으로 볼 수 있다.” <br/>
            <IndustQuoteby>
              <a href="http://www.ohmynews.com/NWS_Web/View/at_pg.aspx?CNTN_CD=A0002618132" target="_blank">박은선(2020), 「청계천 산업생태계 사라지면 이런 것까지 잃는다」, &lt;세운재개발, 무너지는 도시생태&gt; 기획연재시리즈 13, 오마이뉴스.</a>
            </IndustQuoteby>
          </DescBigQuote>
          <DescParagraph>
            청계천·을지로 산업 생태계 특징은 크게 여섯가지로 볼 수 있다. 첫 번째는 청계천·을지로에서는 근거리에서 부자재 및 공구 구입이 가능하고, 이와 동시에 청계천·을지로에서 만들어진 물품들을 유통할 수 있는 구조도 있다. 그렇기 때문에 신속하고 저렴한 제조가 가능하다. 
          </DescParagraph>

          <DescParagraph>
            두 번째는 수리업이다. 청계천 지역에서 유통, 제조, 수리를 겸업하는 점포가 20% 이상을 차지한다. 이들은 각종 의료기기, 화학공학 실험기기, 카메라, 인쇄기기, 미싱기기, 금속 세공 기기를 수리하는 서비스를 제공한다. 만약 이들이 사라진다면 도심산업에 차질이 생길 뿐만 아니라 각종 대학과 연구소의 연구 역량에도 악영향을 끼칠 것이다.
          </DescParagraph>

          <DescParagraph>
            세 번째는 노동자의 숙련도이다. 청계천을지로보존연대가 실시한 실태조사에 따르면 상인들이 청계천에서 일한 경력이 평균 33년으로 나타났다. 이런 정도의 숙련노동자가 1만 명 이상이 집적되어 있는 장소는 세계적으로도 드물다. 한 서울시 관계자는 상인들이 고령화됐으니 공장을 그만둘 때가 되었다고 말했다. 그런데 나이가 든 만큼 노하우도 가지고 있다는 사실을 잊어서는 안 되며, 오히려 숙련 노동을 어떻게 전수할 것인가에 대한 깊은 고민이 필요하다.
          </DescParagraph>

          <DescParagraph>
            넷째, 긴밀한 협력체계이다. 청계천·을지로 산업생태계에서 가장 중요하고 특이한 부분은 바로 협력이다. 청계천·을지로에는 다양한 기술을 가진 노동자들이 있으며, 같은 기계를 써도 특화된 기술이 다르다. 노동자들은 서로 의논하면서 작업하며, 주변 상인들에게 일을 나눠주는 중간 매니저 역할도 수행한다. 이러한 수평적 협력 관계는 대기업 중심의 상하 구조로 창의력을 잃어가고 있는 우리나라 산업 구조를 극복하기 위한 사례로 집중 연구를 수행할 필요가 있다(장웅성, 2017). 
          </DescParagraph>
          <DescParagraph>
            다섯 번째, 다양한 기술을 보유한 공장과 부자재 유통이 한 지역에 밀집되어 있기 때문에 빠르게 생산이 가능하고 저렴하게 제조할 수 있다.
          </DescParagraph>

          <DescParagraph>
            여섯 번째, 청계천과 을지로에서는 대형 공장과 다르게 단 한 개의 기계나 제품도 맞춤형으로 만들 수 있다. 점포의 규모가 작기 때문에 오히려 맞춤 생산이 가능하다. 의료기계나 각종 생화학·공학 실험기계, 예술가들의 창작품은 보통 한두 개 혹은 100개 미만이 필요한 경우가 많다. 그러나 큰 공장에서는 최소 주문수량을 1만 개 부터 시작하는 경우가 많아 다품종 소량 생산을 해야하는 전문업에게는 청계천·을지로의 제조업 플랫폼이 필수적이다. 또한 장인들이 권위적이지 않고 새로운 것에 열려 있어 고객의 의중대로 유연한 맞춤 제작이 가능하다.
          </DescParagraph>

          <DescParagraph>
            마지막으로 청계천의 도시조직과 형태이다. 청계천·을지로는 제조업 클러스터인 동시에, 조선시대 후기 골목 구조가 가장 잘 남아있는 곳이다. 다양한 모양의 필지와 골목은 공업사들과 공구상들이 서로 필요한 것들을 주고 받는 커다란 하나의 제조업 플랫폼이다. 특히 청계천·을지로 골목의 기능이 가든파이브와 같은 아파트형 공장과 가장 다른 점이라고 볼 수 있다. 청계천·을지로에서 골목은 유통로이자, 상인들간의 만남의 장소이자 동시에 작업공간이다.
          </DescParagraph>

          <DescParagraph>
          가게는 협소하지만 골목을 이용해서 가게 폭보다 넓은 크기의 물건도 만들 수 있으며, 크고 무거운 부자재도 가게 바로 앞에서 하루에 몇 번이나 싣고 내릴 수 있는 유통의 혈관으로 작용한다. 서울시가 마련하는 대안에 청계천·을지로 골목에 대한 이러한 특수성과 지리적 특징이 잘 반영되지 않는다면 과거 재개발 대책이었던 ‘장지동 가든파이브’ 사례처럼 실패할 수밖에 없다.
          </DescParagraph>
      </DescContainer>


      <Gutter h={100} />
      <StickyContainer style={{height: windowHeight * 4}}>
        {
          currentSelectedBusiness ? 
          <>
            <BusinessShow /> 
            <BusinessShowCloseBtn />
          </> : null
        }
        <IndustryNetworkMapContainer step={step} touchInteractiveEnabled={this.state.touchInteractiveEnabled} handleTouchInteractiveEnabled={this.handleTouchInteractiveEnabled.bind(this)} />
        <ScrollLabel className="step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight}}>
          <div className="back">
            조사한 을지로 일대 업체들의 위치를 표시한 지도이다. 청계천-을지로 전역에는 약 1만여개의 점포와 공장이 있고, 특히 세운3구역, 5구역, 수표지역에만 천육백 여개의 업체가 있다.
          </div>
        </ScrollLabel>

        <ScrollLabel className="step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 2}}>
          <div className="back">
            <b>산업 생태계 네트워크 (청계천-을지로)</b>
            <Gutter h={10} />
            조사한 업체들의 거래업체 데이터를 시각화한 지도이다. 해당 업체를 클릭하면 간단한 업체정보와 거래하고 있는 업체를 볼 수 있다. 제조업체간의 연결, 유통업체간의 연결 뿐만 아니라 제조업체와 유통업체간의 연결도 상당히 많다.
            <Gutter h={20} />
            <Caveat>
              * 일부 업체정보와 위치가 틀린 경우가 있으나 지속적으로 보완 예정임.
            </Caveat>
          </div>

        </ScrollLabel>


        <ScrollLabel className="step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 3}}>
          <div className="back">
            <b>산업 생태계 네트워크 (전국)</b>
            <Gutter h={10} />
            청계천-을지로의 업체들은 서울 및 수도권 뿐만 아니라 전국 각지와 거래하고 있음을 알 수 있다.
          </div>
        </ScrollLabel>
      </StickyContainer>
    </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight,
    businessesData: state.businessesData,
    currentSelectedBusiness: state.currentSelectedBusiness
  }
}

export default connect(mapStateToProps)(IndustryNetworkPart);