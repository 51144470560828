import React from 'react';
import { windowResize, initData } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, Link } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import TechAgency from './TechAgency';
import FirstPage from './FirstPage';
import { Gutter } from '../stylesheets/components';
import { API_URL } from '../constants/defaults';

const Fragment = React.Fragment;
class Welcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler(); 
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  componentDidUpdate(prevProps){
    if (!prevProps.mapLoaded && this.props.mapLoaded) {
      this.loadData();
    }
  }

  
  async loadData(){
    let filename = `${API_URL}/api/firstpage/all.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(filename, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(initData(data));
      
      // if (this.state.firstEnter) {
      //   this.setState({
      //     firstEnter: false
      //   });
       
      //   if (this.state.outsideMap) {
      //     this.props.dispatch(changeAnimationStatus("play"));
      //   }
      // }
      // // this.props.dispatch(changeAnimationStatus("play"));
      
      // // this.props.dispatch(changeAnimationStatus("play"));
      // this.props.dispatch(changeProgress(false));
      
    } else {
      let data = await response.json();
      // this.props.dispatch(changeProgress(false));

      return data;

    }
  }


  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/">
            <FirstPage />
          </Route>


          <Route exact path="/tech-agency">
            <TechAgency />
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    mapLoaded: state.mapLoaded
  }
}

export default withRouter(connect(mapStateToProps)(Welcome));