import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import gsap from 'gsap';
import { Link, withRouter } from 'react-router-dom';
import media from '../stylesheets/media';
import hamburger from '../assets/hamburger_black.svg';
import close from '../assets/close_black.svg';
const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  width: calc(100vw - 50px);
  padding: 15px 25px;
  display: flex;
  background-color: black;
  justify-content: space-between;
  border-bottom: 1px solid #222;
  z-index: 9999;
  ${media.mobileSmall`
    display: none;
  `}
`;

const HeaderMobileMenu = styled.div`
   position: sticky;
  top: 0;
  width: calc(100vw - 50px);
  padding: 15px 25px;
  height: 22px;
  background-color: black;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #222;
  z-index: 9999;
  display: none;

  
  ${media.mobileSmall`
    display: flex;
  `}
`
const HeaderContainerMobile = styled.header`
  position: sticky;
  top: 40px;
  width: calc(100vw - 50px);
  padding: 15px 25px;
  display: flex;
  background-color: black;
  justify-content: space-between;
  border-bottom: 1px solid #222;
  z-index: 9999;
  display: none;
  ${media.mobileSmall`
    display: flex;
  `}
`;

const HeaderLeft = styled.div`
  display: flex;
  ${media.mobileSmall`
    display: block;
  `}
`
const HeaderTitle = styled.h1`
  font-weight: 900;
`
const HeaderTitleLink = styled.button`
  margin-right: 35px;
  color: white;
  
  ${media.mobileSmall`
    margin-bottom: 10px;
  `}
`
const HeaderMenuList = styled.div`
  display: flex;
  ${media.mobileSmall`
    display: block;
  `}
`
const HeaderMenu = styled.button`
  font-weight: 400;
  
  margin-right: 30px;
  margin-top: -2px;
  font-size:1.0em;
  color: white;
  transition: 0.4s all;
  cursor:pointer;

  &:hover {
    opacity: 0.5;
  }

  ${media.mobileSmall`
    display: block;
    margin-bottom: 3px;
  `}
`
const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  ${media.mobileSmall`
    display: block;
    margin-bottom: 3px;
  `}
`
const HeaderRightLink = styled.button`
  margin-left: 30px;
  font-weight: 400;
  color: white;
  cursor:pointer;
  font-size: 1.0em;
  line-height: 1.4;
  
  ${media.mobileSmall`
    display: block;
    margin:0;
  font-size: 1.0em;
    margin-top: 3px;
  `}
`



const HamburgerBtn = styled.div`
`;
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hamburgerOpen: false
    }
  }

  componentDidMount(){
    // this.scrollDown();
  }

  componentDidUpdate(){
    this.scrollDown();
  }

  scrollDown(){
     // console.log(this.props.match.url);
    let scrollElement = document.scrollingElement || document.documentElement;

     if (this.props.location.hash) {
      var d = document.querySelector(`a[name=${this.props.location.hash.replace('#', '')}]`);

      var bodyRect = document.body.getBoundingClientRect(),
          elemRect = d.getBoundingClientRect(),
          pos = elemRect.top - bodyRect.top;
  
      scrollElement.scrollTop = pos - 80;
      // gsap.to((), 1, { ease: "power3.inOut", scrollTop: });
    }
  }
  handleClick(e){
    
    this.props.history.push(`/#${e}`);
    // if (this.props.match.url === "/tech-agency") {
    //   this.props.history.push(`/#${e}`);
    // } else {

    //   var d = document.querySelector(`a[name=${e}]`);

    //   var bodyRect = document.body.getBoundingClientRect(),
    //       elemRect = d.getBoundingClientRect(),
    //       pos = elemRect.top - bodyRect.top;
  
          
    //   gsap.to((document.scrollingElement || document.documentElement), 1, { ease: "power3.inOut", scrollTop: pos - 80});
    // }
  }

  handleToggleHamburger(e){
    this.setState({
      hamburgerOpen: !this.state.hamburgerOpen
    });
  }


  render() {
    console.log("render width", window.innerWidth);
    let { hamburgerOpen } = this.state;
    return (
      <>
        <HeaderMobileMenu>
          <HeaderTitle>
            청계천, 을지로, 산업 유통 생태계
          </HeaderTitle>
          <HamburgerBtn onClick={this.handleToggleHamburger.bind(this)}>
            {
              hamburgerOpen ? 
              <img src={close} alt="Hamburger Menu" /> : 
              <img src={hamburger} alt="Hamburger Menu" /> 
            }
            
          </HamburgerBtn>
        </HeaderMobileMenu>
        {
          hamburgerOpen ?
          <HeaderContainerMobile>
            <HeaderLeft>
              <HeaderMenuList>

                <HeaderMenu onClick={this.handleClick.bind(this, 'industry-network-part')}>
                  산업 생태계
                </HeaderMenu>
                <HeaderMenu onClick={this.handleClick.bind(this, 'social-capital-part')}>
                  사회적 자본
                </HeaderMenu>
                <HeaderMenu onClick={this.handleClick.bind(this, 'dabang-part')}>
                  다방 이야기
                </HeaderMenu>
              </HeaderMenuList>
            </HeaderLeft>
            <HeaderRight>
              <Link to="/tech-agency">
                기술유통중개소
              </Link>
              <HeaderRightLink onClick={this.handleClick.bind(this, 'credit-part')}>
                크레딧
              </HeaderRightLink>
            </HeaderRight>
          </HeaderContainerMobile> : null 
        }
       
        <HeaderContainer>
          <HeaderLeft>
            <HeaderTitleLink onClick={this.handleClick.bind(this, 'top')}>
              <HeaderTitle>
                청계천, 을지로, 산업 유통 생태계
              </HeaderTitle>
            </HeaderTitleLink>
            <HeaderMenuList>

              <HeaderMenu onClick={this.handleClick.bind(this, 'industry-network-part')}>
                산업 생태계
              </HeaderMenu>
              <HeaderMenu onClick={this.handleClick.bind(this, 'social-capital-part')}>
                사회적 자본
              </HeaderMenu>
              <HeaderMenu onClick={this.handleClick.bind(this, 'dabang-part')}>
                다방 이야기
              </HeaderMenu>
            </HeaderMenuList>
          </HeaderLeft>
          <HeaderRight>
            <Link to="/tech-agency">
              기술유통중개소
            </Link>
            <HeaderRightLink onClick={this.handleClick.bind(this, 'credit-part')}>
              크레딧
            </HeaderRightLink>
          </HeaderRight>
        </HeaderContainer>
      </>
    )
  }
}

let mapStateToProps = state => {
  return {

  }
}

export default withRouter(connect(mapStateToProps)(Header));