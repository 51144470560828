import React, { Component } from 'react'
import DeckGL from '@deck.gl/react';
import {ArcLayer} from '@deck.gl/layers';
import {InteractiveMap, NavigationControl, _MapContext as MapContext } from 'react-map-gl';
import {MapController, FlyToInterpolator, LinearInterpolator}  from 'deck.gl';
import { changeMapLoaded, changeCurrentSelectedBusiness } from '../actions';
import {BrushingExtension} from '@deck.gl/extensions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import GL from '@luma.gl/constants';
import { setColor } from '../utils';
import { API_URL } from '../constants/defaults';
import { Legend } from '.';
import { scaleLinear } from 'd3-scale';

// Viewport settings
const INITIAL_VIEW_STATE =  {
  longitude: 126.99389464034307,
  latitude: 37.567670857854274,
  zoomScale: scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([15.8, 16.8, 17, 17.2, 17.5, 17.5]),
  zoom: 17.5,
  minZoom: 15,
  maxZoom: 22,
  networkShow: false,
  pitch: 0,
  bearing: 0
}

const Container = styled.div`
  width: 100%;
  height: 600px;
  /* position: ; */
  /* top: 0; */
`;

class PoopMapContainer extends Component {
  constructor(props){
    super(props);
    this._staticMapRef = React.createRef();
    this.state = {
      viewState: { ...INITIAL_VIEW_STATE }
    };

  }


  componentDidMount(){
    const waiting = () => {
      let map = this._staticMapRef.current;
      try {

        if (map) {
          let m = map.getMap();
          if (m) {
  
            window.poopMap = m;
            this.map = m;
  
            if (this.map.isStyleLoaded()) {
              console.log("poop loaded");
              this.addLayers();
              // this.startTimer(); HAS TO BE CHANGED TO 
            } else {
              console.log("not loaded");
              setTimeout(waiting, 200);
            }
          } 
        } else {
          console.log("not loaded");
          setTimeout(waiting, 200);
        }
       
      } catch (e) {
        console.log(e);
        setTimeout(waiting, 200);
      }
    
    };

    waiting();
  }

  componentDidUpdate(prevProps){
    // console.log(prevProps.step, this.props.step);

  }

  addLayers(){

    this.map.addSource('building_footprints', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/building_footprints/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('big_roads', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/big_roads/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('alleys', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/alleys/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });


    this.map.addSource('businesses', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/poopcoin/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });



    this.map.addLayer({
      'id': 'big_roads_layer',
      'type': 'line',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      'layout': {},
      'paint': {
        'line-color': '#222',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          19
        ],
        'line-opacity': 1
      }
    });


    this.map.addLayer({
      'id': 'big_roads_text_layer',
      'type': 'symbol',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      "layout": {
        "symbol-placement": "line-center",
        "text-font": ["Spoqa Han Sans Neo Regular"],
        "text-field": ["get", "name"], // part 2 of this is how to do it
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          5,
          18,
          8,
          22,
          15
        ],
      },
      'paint': {
        'text-color': "#555555"
      }
    });



    this.map.addLayer({
      'id': 'alleys_layer',
      'type': 'line',
      'source': 'alleys',
      'source-layer': 'public.alleys',
      'layout': {},
      'paint': {
        'line-color': '#333', //'#17BEE2',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          4
        ],
        'line-opacity': 1
      }
    });



    this.map.addLayer({
      'id': 'building_footprints_layer',
      'type': 'fill-extrusion',
      'source': 'building_footprints',
      'source-layer': 'public.building_footprints',
      'layout': {},
      'paint': {
        'fill-extrusion-color': '#333',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7
      }
    });


    this.map.addLayer({
      'id': 'businesses-circle-layer',
      'type': 'circle',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'paint': {
        'circle-opacity': 1,
        'circle-color': "#F30732",
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    this.map.addLayer({
      'id': 'businesses-text-layer',
      'type': 'symbol',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {
        "text-offset": [0, -1.5],
        "text-letter-spacing": -0.1,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": [
          'format',
          ['get', 'name'],
          {
            'font-scale': 1.0,
            'text-font': ["literal", ["Spoqa Han Sans Neo Regular"]]
          }
        ],
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": 1
      }
    });
  
    this.map.setLayoutProperty('businesses-text-layer', 'text-allow-overlap', true);
    this.map.setLayoutProperty('businesses-text-layer', 'text-ignore-placement', true);
  }


  getTooltip({object}){
    return object && object.properties.name;
  }
  _onClick = event => {
    // event.x and event.y hold the clicked x and y coordinates in pixels
    // You can pass those coordinates to React Map GL's queryRenderedFeatures
    // to query any desired layers rendered there.
    // Make sure you create the ref on InteractiveMap or StaticMap
    const features = this.map.queryRenderedFeatures([event.x, event.y], {
      layers: ["businesses-text-layer", "businesses-circle-layer"]
    });
    
    if (features.length > 0) {
      // if (this.props.step === 3) {
      //   let feature = features[0];
      //   if (feature.properties.poopcoin_participants) {
      //     if (feature.properties.poopcoin_url) {
      //       window.open(feature.properties.poopcoin_url, '_blank');
      //     }
      //   }
      // } else if (this.props.step >= 0 && this.props.step <= 2) {
      //   let feature = features[0];
      //   this.props.dispatch(changeCurrentSelectedBusiness(feature.properties));
      // }
      
        let feature = features[0];
        this.props.dispatch(changeCurrentSelectedBusiness(feature.properties));
      

    }
  }


  _onViewStateChange = viewState => {
    this.setState({ viewState });
  }


  render() {
    let mapStyle = process.env.MAPBOX_STYLE;
    let { step, windowWidth } = this.props;

    var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    return (
      <Container>
        <DeckGL
          ContextProvider={MapContext.Provider}
          layers={[]}
          initialViewState={{
            ...INITIAL_VIEW_STATE,
            zoom: INITIAL_VIEW_STATE.zoomScale(windowWidth)
          }}
          // viewState={viewStateByStep}
          onViewStateChange={this._onViewStateChange}
          onClick={this._onClick}   
          // controller={true}   
          getCursor={() => "pointer"}    
          controller={{type: MapController, dragPan: !supportsTouch, doubleClickZoom: false, scrollZoom: false}}  
        >
          <InteractiveMap 
            reuseMaps
            ref={this._staticMapRef }
            mapStyle={mapStyle}
            preventStyleDiffing={true}
            transitionDuration={10000} transitionInterpolator={new LinearInterpolator()}
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN} />
          <div style={{ position: "absolute", right: 20, top: 20, zIndex: 1 }}>
            <NavigationControl zoomOutLabel="축소" zoomInLabel="확대" />
          </div>
        </DeckGL>
        <Legend step={3} />
      </Container>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    businessesData: state.businessesData
  };
}

export default connect(mapStateToProps)(PoopMapContainer);