import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../stylesheets/media';

const LegendContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 5;

  ${media.mobileLarge`
    right: inherit;
    left: 20px;
  `}
`

export default class SocialCapitalLegend extends Component {
  render() {
    let { step } = this.props;
    return (
      <LegendContainer>
        {
          step === 1 ?
          <svg width="203" height="111" viewBox="0 0 203 111" fill="none">
            <rect x="1" y="1" width="201" height="109" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="14.5" y="26.668">&#xc744;&#xc9c0;&#xb85c; &#xc18d;&#xace8;&#xbaa9;</tspan></text>
            <path d="M15.5 50.5H46.5" stroke="#EEEEEE" strokeWidth="4"/>
            <path d="M15.5 81.25H46.5" stroke="#56BCDE" strokeWidth="4"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="52.5" y="54.296">&#xc18d;&#xace8;&#xbaa9;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em">
              <tspan x="52.5" y="85.046">360도 카메라 촬영 속골목</tspan>
              <tspan x="52.5" y="98.046">(2018.11.)</tspan>
            </text>
          </svg> : null
        }
        {
          step >= 2 && step <= 4 ?
          <svg width="202" height="128" viewBox="0 0 202 128" fill="none">
            <rect x="0.5" y="0.5" width="201" height="127" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="14" y="26.168">&#xce74;&#xd14c;&#xace0;&#xb9ac;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="95" y="26.168">&#xc5f0;&#xacb0; &#xad00;&#xacc4; </tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="53.796">&#xc81c;&#xc870;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="84" y="107.796">&#xc81c;&#xc870;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="173" y="107.796">&#xc720;&#xd1b5;</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="78.796">&#xc720;&#xd1b5;</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="104.796">&#xae30;&#xd0c0;</tspan></text>
            <circle cx="24" cy="50" r="8" fill="#B7F30C"/>
            <circle cx="95" cy="89" r="4" fill="#B7F30C"/>
            <circle cx="24" cy="75" r="8" fill="#0CCAF3"/>
            <circle cx="184" cy="89" r="4" fill="#0CCAF3"/>
            <circle cx="24" cy="101" r="8" fill="#555555"/>
            <path d="M94.9999 88.5C94.8332 73.6667 103.5 44 139.5 44C175.5 44 184.167 73.6667 184 88.5" stroke="url(#paint0_linear)" strokeWidth="2"/>
            <defs>
            <linearGradient id="paint0_linear" x1="184" y1="89" x2="95" y2="89" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0CCAF3"/>
            <stop offset="1" stopColor="#B7F30C"/>
            </linearGradient>
            </defs>
          </svg> : null 
        }
        {
          step >= 5 ?
          <svg width="212" height="126" viewBox="0 0 212 126" fill="none">
            <rect x="0.5" y="0.5" width="210.5" height="124.5" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="16" y="26.168">&#xce74;&#xd14c;&#xace0;&#xb9ac;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="41" y="53.796">&#xc81c;&#xc870;</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="41" y="78.796">&#xc720;&#xd1b5;</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="41" y="104.796">&#xae30;&#xd0c0;</tspan></text>
            <circle cx="26" cy="50" r="8" fill="#B7F30C"/>
            <circle cx="26" cy="75" r="8" fill="#0CCAF3"/>
            <circle cx="26" cy="101" r="8" fill="#555555"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="83.5" y="26.168">&#xc608;&#xc220;&#xac00; &#xc774;&#xc6a9; &#xd328;&#xd134;</tspan></text>
            <path d="M83.5 50H114.5" stroke="url(#paint2_linear)" strokeWidth="4"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="120.5" y="53.796">&#xc608;&#xc220;&#xac00; &#xc774;&#xb3d9; &#xacbd;&#xb85c;</tspan></text>
            <defs>
              <linearGradient id="paint2_linear" x1="83.5" y1="50" x2="114.5" y2="50" gradientUnits="userSpaceOnUse">
              <stop stopColor="#C0C0C0"/>
              <stop offset="1" stopColor="white" stopOpacity="0"/>
              </linearGradient>
            </defs>
          </svg> : null
        }
      </LegendContainer>
    )
  }
}
