import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setColor } from '../utils';
import styled from 'styled-components';
import _ from 'lodash';
import { OrgPhotos } from "./";
import { Gutter } from '../stylesheets/components';

const Fragment = React.Fragment;
const BSContainer = styled.div`
  margin-top: 10px;
  width: 300px;
  background: #000000;
  padding: 22px 22px;
  border-radius: 22px;
`;

const BSTitleArea = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #404040;
  padding-bottom: 5px;
  margin-bottom: 5px;
`


const BSTitle = styled.h3`
  font-weight: 900;
`
const BSCategory = styled.div`
  display:flex;
  align-items: center;
`;
const BSCategoryLabel = styled.div`
  margin-right: 5px;
`
const BSCategoryCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: white;
`

const BSDescArea = styled.div``
const BSLabel = styled.div`
  margin-bottom: 5px;
  color: #777;
  word-break: break-all;
`
const BSValue = styled.div`
  font-weight: 900;
  margin-bottom: 10px;
`

const BSNetworkArea = styled.div`
  margin-top: 20px;
`;

const BSNetworkTitle = styled.div`

  color: #929292;
  b {
    font-weight: 900;
    color: white;
  }

  border-bottom: 1px solid #444;
  padding-bottom: 7px;
  /* margin-bottom: 7px; */

`

const BSNode = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #222;
  padding-bottom: 7px;
  padding-top: 7px;
  /* margin-bottom: 7px; */
  cursor: pointer;
  &:hover {
    background-color: rgba(22, 22, 22, 0.5);
  }
`

const BSNodeName = styled.div`
  color: white;
  font-weight: 500;
`



class OrgInfoBox extends Component {

  getOrganization(){
    let { currentOrgId, organizationsData } = this.props;

    return _.find(organizationsData, ad => {
      return ad.id === currentOrgId;
    })
  }

  render() {
    let { currentOrgPhotos } = this.props;
    let organization = this.getOrganization();

    return (
      <BSContainer>
        <BSTitleArea>
          <BSLabel>
            { organization.org_type }
          </BSLabel>
        </BSTitleArea>
        <Gutter h={5} />
        <BSDescArea>
          <Gutter h={5} />
          <BSLabel>
            {organization.notes}
          </BSLabel>
        </BSDescArea>
        {
          currentOrgPhotos.length > 0 ? 
          <OrgPhotos /> : null
        }
      </BSContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    organizationsData: state.organizationsData,
    currentOrgId: state.currentOrgId,
    currentOrgPhotos: state.currentOrgPhotos
  }
}

export default connect(mapStateToProps)(OrgInfoBox);