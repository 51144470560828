import React, { Component } from 'react'
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SC_STEP_ZOOM } from '../constants/defaults';
import { changeCurrentArtistId, changeCurrentOrgId } from '../actions';
import { ArtistQuestionBox } from './';

const Container = styled.div`
  position: absolute;
  left: 20px;
  top: 65px;
  z-index: 5;
  width: 200px;
`;

const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      transition: "none",
      padding: "0px 10px 0 16px",
      borderRadius: 16,
      backgroundColor: "black",
      border: '1px solid #444',
      lineHeight: 1,
      minHeight: 32
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    border: "none",
    marginLeft: 0,
    borderRadius: 0
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none"
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      borderRadius: 16,
      padding: 5,
      margin: 0,
      zIndex: 999,
      width: 200,
      top: "calc(100% + 8px)",
      overflow: "hidden",
      boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
      backgroundColor: "white",
      color: "black"
    }
  },
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    paddingLeft: 11,
    backgroundImage: `linear-gradient(to right, black 25%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: "bottom",
    backgroundSize: "4px 1px",
    backgroundRepeat: "repeat-x",                    
    '&:hover, &:active': {
      backgroundColor: "white"
    },
    backgroundColor: "white",
    color: "black",
    "&:first-of-type": {
      paddingTop: 0
    },  
    "&:last-child": {
      backgroundImage: "none",
      paddingBottom: 0,
      marginBottom: 0
    }
  })
};


class ArtworkSelectBox extends Component {

  handleChange(e){
    this.props.dispatch(changeCurrentArtistId(e.value));
  }

  render() {
    
    let { artistsData, currentArtistId, step } = this.props;
    let options = _.map(artistsData, artist => {
      return {
        label: `작가 #${artist.id}`,
        value: artist.id
      }
    })

    let selectedArtistValue;
    selectedArtistValue = _.find(options, option => {
        return option.value === currentArtistId
      });


    return (
      <Container>
        <Select 
          options={options} 
          isSearchable={true}
          placeholder="작가 선택..."
          value={selectedArtistValue}
          onChange={this.handleChange.bind(this)}
          styles={selectStyles} />
        {
          currentArtistId ? 
          <ArtistQuestionBox /> : null 
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    artistsData: state.artistsData,
    currentArtistId: state.currentArtistId
  }
}

export default connect(mapStateToProps)(ArtworkSelectBox);