import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DabangMapContainer, DabangSlider } from '../components';
import { DescContainer, DescBigQuote, DescTitle, DescParagraph, Gutter, Quoteby, Caveat, MobileMapTransparentBlocker } from '../stylesheets/components';
import dabang_photo from '../assets/dabang_photo.png';
import cloth_01 from '../assets/cloth_01.png';
import cloth_02 from '../assets/cloth_02.png';
import subtitle_img from '../assets/subtitle_img.svg';
import bottle_01 from '../assets/bottle_01.png';
import bottle_02 from '../assets/bottle_02.png';
import bottle_03 from '../assets/bottle_03.png';
import media from '../stylesheets/media';

const Fragment = React.Fragment;


const CoverImg = styled.img`
  width: 100%;
`

const CoverTitleArea = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: -70px;
  display: flex;
  align-items: flex-end;

  ${media.smallDesktop`
    width: calc(100% - 40px);
  `}
  
  ${media.mobileSmall`
    margin-top:-38px;
    display: block;
  `}
`
const CoverTitle = styled.div`
  font-size: 7.142em;
  line-height: 1.2;
  font-weight: 900;

  ${media.mobileSmall`

    font-size: 4em;
  `}

`
const Subtitle = styled.div`
  margin-bottom: 7px;
  margin-left: 20px;

  ${media.mobileSmall`
    margin:0;
    margin-top: 10px;
  `}
`
const SectionArea = styled.div`
  width: 1200px;
  margin: 0 auto;

  ${media.smallDesktop`
    width: calc(100% - 40px);
  `}
`;

const ColumnTitle = styled.div`
  font-weight: 900;
  font-size: 1.285em;
  letter-spacing: .0125em;
  line-height: 1.75;
  margin: 25px 0;
  
  ${media.mobileSmall`
    font-size: 1.1em;
  `}
`
const Quote = styled.div`
  margin: 50px 0 50px 140px;
  font-weight: 900;
  font-size: 1.5em;
  letter-spacing: .0125em;
  width: 600px;
  line-height: 1.75;

  z-index: 3;
  position: relative;

  ${media.mobileLarge`
    margin: 50px 0 50px 80px;
  `}
  ${media.mobileSmall`
    width: 100%;
    font-size: 1.1em;
    margin: 15px 0;
  `}
`

const FullPhotoArea = styled.div`
  margin: 10px 0;
`
const PhotoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    margin: 0 10px;
    width: calc(40% - 20px);
    filter: grayscale(100%);
    transition: 0.4s filter;
    &:hover {
      filter: grayscale(0);
    }


    ${media.mobileSmall`
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    `}
  }


  ${media.mobileSmall`
    display: block;
  `}
`
const Caption = styled.div`
  color: #555;
  font-size: 0.8em;
`

const DabanagDisplacementArea = styled.div`
  width: calc(100% - 40px);
  margin: 0 auto;
  position: relative;
`;

const FullPhotoCaption = styled.div`
  text-align:center;
  font-size:1em;
  margin: 15px 0;

`
const BigQuote = styled.div`
  margin: 25px 0 25px 0;
  font-weight: 900;
  font-size: 50px;
  letter-spacing: .0125em;
  width: 600px;
  line-height: 1.3;
  z-index: 3;
  position: relative;

  
  ${media.mobileSmall`
    font-size: 2em;
    width: 100%;
  `}
`
const ColumnArea = styled.div`
  position: relative;
`


const FloatImg = styled.img`
  position: absolute;
  left: ${props => `calc(50% ${props.offset}px)`};
  top: 0;
  width: 450px;
  z-index:1;
  filter: grayscale(100%);
  transition: 0.4s all;
  &:hover {
    filter: grayscale(0);

    ${media.padLandscape`
      opacity: 1;
    `}
  }

  
  ${media.padLandscape`
    left: ${props => `calc(50% ${props.offset}px)`};
    opacity: 0.5;
  `}


  ${media.mobileLarge`
    left: ${props => `calc(30% ${props.offset}px)`};
    opacity: 0.5;
  `}


  ${media.mobileSmall`
    width: 300px;
    left: 10%;
    opacity: 0.5;
  `}
`;

const DescParagraphDabang = styled(DescParagraph)`
  margin: 0;
  z-index: 3;
  position: relative;

  
  ${media.mobileLarge`
    width: 100%;
  `}
`;

const DabangDeliverArea = styled.div`
  position: relative;
  
`;

class DabangPart extends Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }

  render() {
    let { windowHeight, windowWidth } = this.props;

    var supportsTouch = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||  (navigator.msMaxTouchPoints > 0));
 
    return (
      <Fragment>
        <a name="dabang-part"></a>

        <Gutter h={50} />
        <CoverImg src={dabang_photo} alt="다방 전경" />
        <CoverTitleArea>
          <CoverTitle>
            다방에서 본<br/>
            사회적 자본
          </CoverTitle>
          <Subtitle>
            <Caption>* 을지로 백합다방 주방의 모습</Caption>
            <Gutter h={122} />
            을지로의 다방 이야기
          </Subtitle>
        </CoverTitleArea>
        
        <Gutter h={50} />
        <SectionArea>

          <ColumnTitle>
            지역에서 함께 산 오랜 세월
          </ColumnTitle>
          <DescParagraphDabang>
            광신공업사 사장님은 L사장님(T다방 대표)이 입사한 무렵의,{ windowWidth >= 414 ? <br/> : null }
            전성기 시절을 기억한다.
          </DescParagraphDabang>

          <Quote>
            “L사장님이 T다방 입사할 때만 해도 직원이 5명이었을 거야. ‘코끼리가 웃는다’ 팀이 놀러오기도 했던 게 기억나. T다방은 직원이 9명 정도였을 정도로 규모가 컸었어. 테이블도 꽤 많았고.”
            <Quoteby>
              광신공업사 이영건
            </Quoteby>
          </Quote>

          <FullPhotoArea>
            <PhotoArea>
              <img src={cloth_01} alt="다방 사장님들의 파란 배달 주머니" />
              <img src={cloth_02} alt="다방 사장님들의 분홍 배달 주머니" />
            </PhotoArea>
            <FullPhotoCaption>
              다방 사장님들의 배달 보자기.
            </FullPhotoCaption>
          </FullPhotoArea>

          <DescParagraphDabang>
            하지만, 2018-2019년, M다방과 T다방은 재개발 사업으로 인해{ windowWidth >= 414 ? <br/> : null } 
            시행사가 제공한 컨테이너 박스로 이주하게 되었다.
          </DescParagraphDabang>
          
          
        </SectionArea>
        <DabanagDisplacementArea>
          
          {
            supportsTouch ? 
            <MobileMapTransparentBlocker />  : null  
          }
          
          <DabangMapContainer mode="displacement" />
        </DabanagDisplacementArea>

        <SectionArea>

          <BigQuote>
            “계속 같이 있게 되니까 서로를 더 잘 알게 되는 거지. 다 같이 늙어가.” 
            <Quoteby>
              광신공업사 이영건
            </Quoteby>
          </BigQuote>
          <DescParagraphDabang>
            그럼에도 다방사장님들과의 인연을 놓을 수 없다. { windowWidth >= 414 ? <br/> : null }
            다방사장님들의 단골 업체들이 있기에, { windowWidth >= 414 ? <br/> : null }
            다방 사장님들도 이곳을 져버리고 떠날 수 없다.
          </DescParagraphDabang>
          <DescParagraphDabang>
            그럼에도 정밀업체 사장님들은 다방사장님들과의 인연을 놓을 수 없다.{ windowWidth >= 414 ? <br/> : null }
            다방 사장님들도 단골 업체들이 있기에,{ windowWidth >= 414 ? <br/> : null }
            이곳을 져버리고 떠날 수 없다.{ windowWidth >= 414 ? <br/> : null }{ windowWidth >= 414 ? <br/> : null }
            세월은 그냥 흐르지 않는다. 한 세월을 함께하며 이 지역의 사장님들은 서로가 가족보다 더 친하고 동네 사정도 잘 아는 사이가 되었다. 그렇게 끈끈한 사람들이 재개발 사업 때문에 점점 떠나기 시작하자, M다방 사장님에게는 ‘사람을 못 본다는 게 엄청난 후유증’ 이 되었다.
          </DescParagraphDabang>

          <Quote>
            “(거래처는 물론 이웃) 사람들이 사람들 하나둘 없어지니까 엄청 휑하고 허전하고 그래. (...) 세월이라는 게 돈으로 살 수 없잖아. 그 많은 세월 동안 다른 (...) 좋으신 분들도 엄청 많았었지.”
            <Quoteby>
              L사장님
            </Quoteby>
          </Quote>
        </SectionArea>

        <Gutter h={100} />
        
        <SectionArea>
          <img src={subtitle_img} alt="로고 이미지" />
          <ColumnTitle>
            섬세한 관찰자, 청자,<br/>
            이 지역을 가장 잘 아는 <br/>
            동료 
          </ColumnTitle>

          <ColumnArea>


            <FloatImg 
              src={bottle_01} 
              alt="bottle_01" 
              offset="+ 20"
              style={{
                top: 50
              }}
            />
            <FloatImg src={bottle_02} alt="bottle_02" offset="- 50" style={{
              top: 300
            }}/>
            <FloatImg src={bottle_03} alt="bottle_03"  offset="- 20"  style={{
              top: 550
            }}/>
            
            <DescParagraphDabang>
              다방 사장님들은 이 지역의 오랜 관찰자이자 참여자다. { windowWidth >= 414 ? <br/> : null }
              정밀업체 사장님들 마다 가진 작업 공정의 특성과 작업 공간의 개성을 잘 안다. { windowWidth >= 414 ? <><br/><br/></> : null } 
              
              L사장님은 신한정밀 사장님이 ‘이렇게 정리 정돈 잘 해놓은 사람 별로 없을 정도’로 이 지역에서 정리정돈이라면 으뜸가는 사람이란 걸 알고 있다. <br/><br/>
              
              다방 사장님들은 상대방이 대화할 준비가 되어 있는지 살필 정도로 섬세한 분들이다. 주문받아 작업장에 도착해 차를 만들 때도 사장님들이 어떤 상황인지를 살피며 배려한다. 일례로, 프레스 기계는 가능한 한 많은 개수를 찍어내야 하기 때문에 '시간 싸움'이다. 그 공정을 잘 알고 있는 T다방 사장님은, 말을 걸면 혹시라도 사고가 날 까봐 알아서 대화를 절제하는 편이다.
            </DescParagraphDabang>

            <Quote>
              “일할 때는 모른 척 있다가 할 일 하고 나와. 여긴 다 그래. (...) 괜히 조심하지 않으면 상처생길 수 있고 위험하잖아. 여기 기계 다 위험하더라고 보니까.”
              <Quoteby>
                L사장님
              </Quoteby>
            </Quote>

            <DescParagraphDabang>
              상대방의 마음도 보인다. 속이 어떤지, 요즘 기분이 어떤지를 다 안다. { windowWidth >= 414 ? <br/> : null }
              워낙 단골로 오래 지내다보니 단골을 넘어서 친구가 된 덕분이다. 
            </DescParagraphDabang>

            <Quote>
              “그냥 가서 얼굴 보고 얘기하면 그 사람 오늘 기분이 안 좋은 거 같으면 거기서 수다 떨 필요가 없잖아. 그렇게 다 알잖아 서로가. 오래 있다 보니까. 매일 보던 사람들. 여기 사람들은 거의 다 단골이잖아. 단골들은 거의 다 친해. 대부분 다 알어. 그 사람들의 성품, 요즘 또 기분이 어떤지, 일은 좀 있는지 없는지(웃음). 너무 잘 알지 우리가..”
              <Quoteby>
                L사장님
              </Quoteby>
            </Quote>

            <DescParagraphDabang>
              M다방 사장님은 청계천이 동료 업체 사장님들에게 선뜻 공구를 빌려줄 만큼 인정 가득한 지역이란 걸 안다. 지역 안을 오가며 정밀·공구 업체 사장님들의 대화를 보고 들으며 알게 된 것들이다. 
            </DescParagraphDabang>

            <Quote>
              “공장 사람들 보면 공구들이 다 비싸. 그래서 갑자기 공구가 망가지면, 여건이 안 되는 사람이 주변 사람들한테 빌려달라고 할 수 있잖아? 그러면 빌려주고. 그런데 생판 모른 다른 데 가서 '공구 망가져서 잠깐만 빌려주세요'라는 말이 선뜻 나오겠어? 안 나오는 거지.”
              <Quoteby>
                M다방 사장님
              </Quoteby>
            </Quote>
          </ColumnArea>
        </SectionArea>


        <Gutter h={100} />
        <SectionArea>
          <img src={subtitle_img} alt="로고 이미지" />
          <ColumnTitle>
            서로를 살피고<br/>
            위하는 상부상조
          </ColumnTitle>

          <DescParagraphDabang>
            다방 사장님들이 지역에 제공하는 것은 비단 커피나 차뿐만이 아니다.{ windowWidth >= 414 ? <br/> : null }
            무더위로 지치기 쉬운 여름엔 아침마다 식수용 얼음물을 배달한다. { windowWidth >= 414 ? <br/> : <> </> }
            정밀업체 사장님들이 출근도 하기 전, { windowWidth >= 414 ? <br/> : <> </> }
            골목에는 다방 사장님들이 작업장 앞에 두고 간{ windowWidth >= 414 ? <br/> : null }
            얼음물통을 볼 수 있다. { windowWidth >= 414 ? <br/> : null }
            일례로 S다방의 경우 약 30군데를 배달하며 얼음물 또한 { windowWidth >= 414 ? <br/> : null }
            단골 업체에 주로 배달을 한다고 한다. 또한 업체마다 선호하는 물의 종류가 달라{ windowWidth >= 414 ? <br/> : null }
            보리차를 배달해주기도 한다.<br/><br/>

            얼음물뿐 아니라 수건, 작업복 등도 세탁해 제공한다. { windowWidth >= 414 ? <br/> : null }
            냉장고나 빨래할 수 있는 다용도실이 없는 정밀업체 작업장의 환경을 { windowWidth >= 414 ? <br/> : <> </> }
            다방사장님들이 보완해주고 있는 셈이다. 
          </DescParagraphDabang>
          
          <DabangDeliverArea>

            {
              supportsTouch ? 
              <MobileMapTransparentBlocker />  : null  
            }
            
            <DabangSlider />
            <DabangMapContainer mode="delivery" />
          </DabangDeliverArea>
          
            <Caveat>
              * S다방의 경우는 식수통이 놓여져있는 업체정보를 토대로 최단길을 이어그렸으므로 실제 배달경로와 다를 수 있음.
            </Caveat>
          <Gutter h={20} />

          <DescParagraphDabang>
            이러한 관계에 대한 해석은 다방 사장님들마다 차이를 보였다. 거래를 유지하기 위한 서비스 제공에 지나지 않다는 견해를 밝힌 분도 있었지만, 이에 더해 '상부상조'의 미덕으로 해석하고 수행하는 분도 있었다. 후자는 정밀업체 사장님들과의 관계를 교우하는 동료 관계로 바라보기에 가능한 관점이었다. 
            M다방 사장님의 경우, 한 정밀업체 사장님의 작업복을 세탁하는 상황에 대해 이렇게 설명했다.
          </DescParagraphDabang>
          <Quote>
            “사장님들이 돈이 없으니까, 한푼이라도 아낀다는데 어떻게 해. 이렇게 하면 나한테도 커피로 갚아. 꼭 그게 아니더라도, (사장님들 사정 때문에) 안 할 수가 없어.”
            <Quoteby>
              M다방 사장님
            </Quoteby>
          </Quote>

          <DescParagraphDabang>
            정밀업체 사장님의 영세한 상황을 이해하고 배려하는 마음이 우선한다. 다방 사장님들과 서로의 영세한 상황을 이해하고 배려하는 건 정밀업체 사장님들도 마찬가지다. 일대의 정밀업체에서는 9시 출근 시각에 모닝커피를 주문해 먹는다. 한 정밀업체 사장님에 따르면, 9시 모닝커피도 다방 사장님들을 돕기 위해 시작한 의도가 있었다. 어떤 정밀업체에서는 특정 다방만 애용하지 않고, 지역의 다방을 두루두루 애용한다. 한 집도 부족하지 않게 고루고루 건사했으면 하는 마음에서다. 
          </DescParagraphDabang>
          <Quote>
            “지금 반 이상 다 나갔어, 내 거래처가. 그러니까 지금 장사가 옛날보다 반도 뭐 되나마나 할 걸. 장사가 잘 안 돼. 그래도 나머지 있는 사람들이 되게 잘 해주니까. 많이 시켜주려고 애써. (...) 그런데 여기 어떻게 보면 진짜, 되게 이상한 게 자기 거래처를 다 엄청 도와주려고 애써.”
            <Quoteby>
              T다방 사장님
            </Quoteby>
          </Quote>
          
          <DescParagraphDabang>
            L사장님은 주변 정밀업체 사장님들과 점심 식사를 같이 한다. 코로나19로 인해 식당 방문이 어려워지면서 타임다방의 테이블에 옹기종기 모여 식사를 한다. 정밀업체 사장님들은 그의 업무 시간에 맞추어 점심 시간을 정했다.
          </DescParagraphDabang>
          
          <Quote>
            “(다방 사장님들은) 거의 다 혼자서 먹어. 솔직히, 또 시간이 안 맞잖아. 그래서 우리는 일찍 밥 먹어. 나 때문에. 11시 반에 먹어. 같이. 여기는 12시 되어야 먹거든. 근데 우리는, 내가 배달 들어오면 또 가야 되니까.”
            <Quoteby>
              L사장님
            </Quoteby>
          </Quote>

          <DescParagraphDabang>
            서로를 살피고 위하는 상부상조의 관계는 집안 경사를 챙기는 문화에서도 볼 수 있다. 2020년 S다방 사장님의 따님 결혼식에 입정동의 100여개 정밀업체 사장님들이 축의금을 보탠 것이나, L 사장님의 자녀 결혼식 또한, 정밀업체 사장님들의 축하를 받았다.
          </DescParagraphDabang>

          <Quote>
            “결혼하면 우리 서로가 다 부조하고 또 가서 축하해주고 하지. 다 그래. 우리 딸이 결혼할 때도 다 왔어. (...) 그래서 또 같이 밥 한끼 먹고 그래.”
            <Quoteby>
              L사장님
            </Quoteby>
          </Quote>
        </SectionArea>

        <Gutter h={200} />

      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(DabangPart);