import React, { Component } from 'react'
import DeckGL from '@deck.gl/react';
import {TripsLayer} from '@deck.gl/geo-layers';
import {InteractiveMap, NavigationControl, _MapContext as MapContext, Marker } from 'react-map-gl';
import {MapController, FlyToInterpolator, LinearInterpolator}  from 'deck.gl';
import { changeDabangMapLoaded } from '../actions';
import {BrushingExtension} from '@deck.gl/extensions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import GL from '@luma.gl/constants';
import gsap from "gsap";
import { setColor } from '../utils';
import { API_URL, DABANGS, DABANG_DELIVERY_PATH, DABANG_PATH_DECK, DABANG_BOTTLES } from '../constants/defaults';
import { Legend } from '.';
import { map_range } from '../utils';
import media from '../stylesheets/media';
import { scaleLinear } from 'd3-scale';
const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;

const zoomScale = scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([16.7, 17.5, 17.5, 17.5, 17.5, 17.5]);
const zoomScaleDelivery = scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([17, 17.5, 17.5, 17.6, 18.2, 18.2]);

// Viewport settings
const INITIAL_VIEW_STATE = {
  longitude: 126.99421774663908,
  latitude: 37.56778722440234,
  zoom: 17,
  maxZoom: 22,
  pitch: 0,
  bearing: 0
}

const INITIAL_VIEW_STATE_DELIVERY = {
  time: {
    longitude: 126.99414536168283,
    latitude: 37.56794824346959,
    zoom: 18,
    maxZoom: 22
  }, 
  sujung: {
    longitude: 126.99345653710527, 
    latitude: 37.56727270137099,
    zoom: 18.2,
    maxZoom: 22
  }
}

const Container = styled.div`
  width: 1200px;
  height: 700px;
  margin: 30px auto;
  position:relative;

  ${media.smallDesktop`
    width: 100%;
    height: 500px;
  `}
`;

const BottleImg = styled.img`
  opacity: 1;
  transition: 0.4s opacity;
`;

class DabangMapContainer extends Component {
  constructor(props){
    super(props);
    this._staticMapRef = React.createRef();
    this.state = {
      currentTime: 0,
      deliberyDabang: "sujung",
      viewState: { ...INITIAL_VIEW_STATE }
    };

  }


  componentDidMount(){
    const waiting = () => {
      let map = this._staticMapRef.current;
      try {

        if (map) {
          let m = map.getMap();
          if (m) {
  
            window[`dabangMap_${this.props.mode}`] = m;
            this.map = m;
  
            if (this.map.isStyleLoaded()) {
              console.log("loaded");
              this.addLayers();
              this.props.dispatch(changeDabangMapLoaded(true));
              // this.startTimer(); HAS TO BE CHANGED TO 
            } else {
              console.log("not loaded");
              setTimeout(waiting, 200);
            }
          } 
        } else {
          console.log("not loaded");
          setTimeout(waiting, 200);
        }
       
      } catch (e) {
        console.log(e);
        setTimeout(waiting, 200);
      }
    
    };

    waiting();
  }


  componentWillUnmount(){
    this.props.dispatch(changeDabangMapLoaded(false));
  }
  
  componentDidUpdate(prevProps){
    if (this.props.dabangMapLoaded){
      if (prevProps.dabangDeliveryName !== this.props.dabangDeliveryName){
        this.updateDabangDelivery(this.props.dabangDeliveryName);
      }
    }
  }

  updateDabangDelivery(dabangDeliveryName){
    let pathSource = this.map.getSource("dabang_delivery_path");
    if (pathSource) {
      pathSource.setData(DABANG_DELIVERY_PATH[dabangDeliveryName]);
    }
    this.map.setPaintProperty('dabang_delivery_path_layer', 'line-color', this.props.dabangDeliveryName === "time" ? '#5F5CE1' : "#dbc863");

    this.map.removeLayer('businesses-circle-layer');
    this.map.removeLayer('businesses-text-layer');
    this.map.removeSource("businesses");

    this.map.addSource('businesses', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/dabangs/{z}/{x}/{y}.pbf?dabang_name=${dabangDeliveryName}`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addLayer({
      'id': 'businesses-circle-layer',
      'type': 'circle',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {},
      'paint': {
        'circle-opacity': 0.7,
        'circle-color': [
          'case',
          ["==", ['get', 'category'], "제조"],
          '#B7F30C',
          ["==", ['get', 'category'], "유통"],
          '#0CCAF3',
          '#999'
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    this.map.addLayer({
      'id': 'businesses-text-layer',
      'type': 'symbol',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {
        "text-allow-overlap": true,
        "text-offset": [0, -1.5],
        "text-letter-spacing": -0.1,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": [
          'format',
          ['get', 'name'],
          {
            'font-scale': 1.0,
            'text-font': ["literal", ["Spoqa Han Sans Neo Regular"]]
          }
        ],
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": 1
      }
    });
  }
  
  addLayers(){

    // this.map.addSource('building_footprints', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/building_footprints/{z}/{x}/{y}.pbf`],
    //   "minZoom": 15,
    //   "maxZoom": 22
    // });

    this.map.addSource('big_roads', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/big_roads/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    // this.map.addSource('alleys', {
    //   "type": 'vector',
    //   "tiles": [`${API_URL}/tiles/alleys/{z}/{x}/{y}.pbf`],
    //   "minZoom": 15,
    //   "maxZoom": 22
    // });


    this.map.addSource('businesses', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/dabangs/{z}/{x}/{y}.pbf?dabang_name=${this.props.dabangDeliveryName}`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource("dabangs", {
      "type": "geojson",
      "data": DABANGS
    });

    if (this.props.mode === "delivery") {
      this.map.addSource("dabang_delivery_path", {
        "type": "geojson",
        "data": DABANG_DELIVERY_PATH[this.props.dabangDeliveryName]
      });


    }


    this.map.addLayer({
      'id': 'big_roads_layer',
      'type': 'line',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      'layout': {
        'line-join': "round"
      },
      'paint': {
        'line-color': '#222',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          14
        ],
        'line-opacity': 1
      }
    });


    this.map.addLayer({
      'id': 'big_roads_text_layer',
      'type': 'symbol',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      "layout": {
        "symbol-placement": "line-center",
        "text-font": ["Spoqa Han Sans Neo Regular"],
        "text-field": ["get", "name"], // part 2 of this is how to do it
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          5,
          18,
          8,
          22,
          15
        ],
      },
      'paint': {
        'text-color': "#555555"
      }
    });



    // this.map.addLayer({
    //   'id': 'alleys_layer',
    //   'type': 'line',
    //   'source': 'alleys',
    //   'source-layer': 'public.alleys',
    //   'layout': {},
    //   'paint': {
    //     'line-color': '#17BEE2',
    //     'line-width': [
    //       'interpolate',
    //       ['linear'],
    //       ['zoom'],
    //       16,
    //       0.5,
    //       18,
    //       4
    //     ],
    //     'line-opacity': 0.3
    //   }
    // });




    // this.map.addLayer({
    //   'id': 'building_footprints_layer',
    //   'type': 'fill',
    //   'source': 'building_footprints',
    //   'source-layer': 'public.building_footprints',
    //   'layout': {},
    //   'paint': {
    //     'fill-color': '#333',
    //     'fill-opacity': 0.7,
    //     'fill-outline-color': '#444'
    //   }
    // });

    let deliveryOpacityOff = [
      'case',
      ["==", ['get', 'name'], "M다방"],
      1,
      ["==", ['get', 'name'], "T다방"],
      1,
      ["==", ['get', 'name'], "S다방"],
      1,
      0
    ];

    this.map.addLayer({
      'id': 'dabangs-circle-layer',
      'type': 'circle',
      'source': 'dabangs',
      'layout': {
        
      },
      'paint': {
        'circle-opacity': this.props.mode === "delivery" ? deliveryOpacityOff : 1,
        'circle-color': [
          'case',
          ["==", ['get', 'code'], "MC"],
          '#DBC863',
          ["==", ['get', 'code'], "TC"],
          '#5F5CE1',
          'rgba(0, 0, 0, 0)'
        ],
        'circle-stroke-color': [
          'case',
          ["==", ['get', 'code'], "MB"],
          '#DBC863',
          ["==", ['get', 'code'], "TB"],
          '#5F5CE1',
          'rgba(0, 0, 0, 0)'
        ],
        'circle-stroke-width': [
          'case',
          ["==", ['get', 'code'], "MB"],
          2,
          ["==", ['get', 'code'], "TB"],
          2,
          0
        ],
        'circle-stroke-opacity': this.props.mode === "delivery" ? deliveryOpacityOff : 1,
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    if (this.props.mode === "delivery") {
      this.map.addLayer({
        'id': 'dabang_delivery_path_layer',
        'type': 'line',
        'source': 'dabang_delivery_path',
        'layout': {},
        'paint': {
          'line-color': this.props.dabangDeliveryName === "time" ? '#5F5CE1' : "#dbc863",
          'line-width': [
            'interpolate',
            ['linear'],
            ['zoom'],
            16,
            0.5,
            18,
            2
          ],
          'line-opacity': 0.2
        }
      });
    }
    this.map.addLayer({
      'id': 'dabangs-text-layer',
      'type': 'symbol',
      'source': 'dabangs',
      'layout': {
        "text-offset": [0, -1.5],
        "text-allow-overlap": true,
        "text-letter-spacing": -0.05,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"]
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": this.props.mode === "delivery" ? deliveryOpacityOff : 1
      }
    });



    if (this.props.mode === "delivery") {
      
      this.map.addLayer({
        'id': 'businesses-circle-layer',
        'type': 'circle',
        'source': 'businesses',
        'source-layer': 'public.businesses',
        'layout': {},
        'paint': {
          'circle-opacity': 0.7,
          'circle-color': [
            'case',
            ["==", ['get', 'category'], "제조"],
            '#B7F30C',
            ["==", ['get', 'category'], "유통"],
            '#0CCAF3',
            '#999'
          ],
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            2,
            16,
            2,
            18,
            4.5,
            22,
            8
          ],
        }
      });

      this.map.addLayer({
        'id': 'businesses-text-layer',
        'type': 'symbol',
        'source': 'businesses',
        'source-layer': 'public.businesses',
        'layout': {
          "text-allow-overlap": true,
          "text-offset": [0, -1.5],
          "text-letter-spacing": -0.1,
          "text-size": [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            10,
            16,
            10,
            18,
            12,
            22,
            16
          ],
          "text-field": ['get', 'name'],
          "text-font": ["Noto Sans KR Medium"]
        },
        'paint': {
          "text-color": "#FFFFFF",
          "text-opacity": 1
        }
      });
    }

  }




  _onViewStateChange = viewState => {
    this.setState({ viewState });
  }

  renderLayers(){
    let { dabangDeliveryCurrentTime } = this.props;

    return [
      new TripsLayer({
        id: 'dabangMovements',
        data: [DABANG_PATH_DECK[this.props.dabangDeliveryName]],
        getPath: d => {
          return d.path;
        },
        getTimestamps: d => d.timestamps,
        getColor: d => {
          return this.props.dabangDeliveryName === "time" ? [95, 92, 225] : [219, 200, 99];
        },
        opacity: 1,
        widthMinPixels: 3,
        rounded: true,
        trailLength: 0.1,
        currentTime: map_range(dabangDeliveryCurrentTime, 0, 50, 0, this.props.dabangDeliveryName === "time" ? 7.45 : 6),
        shadowEnabled: false
      })
    ];
  }


  render() {
    let mapStyle = process.env.MAPBOX_STYLE;
    let { windowWidth, dabangMapLoaded, dabangDeliveryCurrentTime } = this.props;

    var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    return (
      <Container>
        <DeckGL
          ContextProvider={MapContext.Provider}
          layers={dabangMapLoaded && this.props.mode === "delivery" ? this.renderLayers() : []}
          initialViewState={{
            ...(this.props.mode === "delivery" ? INITIAL_VIEW_STATE_DELIVERY[this.props.dabangDeliveryName] : INITIAL_VIEW_STATE),
            zoom: this.props.mode === "delivery" ? zoomScaleDelivery(windowWidth) : zoomScale(windowWidth)
          }}
          onViewStateChange={this._onViewStateChange}
          onClick={this._onClick}   
          getCursor={() => "pointer"}    
          controller={{type: MapController, dragPan: !supportsTouch, doubleClickZoom: false, scrollZoom: false}}  
        >
          <InteractiveMap 
            reuseMaps
            ref={this._staticMapRef }
            mapStyle={mapStyle}
            preventStyleDiffing={true}
            transitionDuration={10000} transitionInterpolator={new LinearInterpolator()}
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}   
          >
            {

              _.map(DABANG_BOTTLES[this.props.dabangDeliveryName].features, bottle_geojson => {
                {/* console.log(dabangDeliveryCurrentTime); */}
                return (
                  <Marker 
                    key={bottle_geojson.properties.appear_time}
                    latitude={bottle_geojson.geometry.coordinates[1]} 
                    longitude={bottle_geojson.geometry.coordinates[0]}
                    offsetLeft={(-bottle_geojson.properties.width * 0.5)}
                    offsetTop={-bottle_geojson.properties.height - 20}
                  >
                    <BottleImg 
                      style={{
                        width: bottle_geojson.properties.width,
                        height: bottle_geojson.properties.height,
                        opacity: 
                          bottle_geojson.properties.appear_time < dabangDeliveryCurrentTime && 
                          bottle_geojson.properties.disappear_time > dabangDeliveryCurrentTime ? 1 : 0
                         }}
                      src={bottle_geojson.properties.url} />
                  </Marker>
                )
              })
            }
          </InteractiveMap>
          {/* <div style={{ position: "absolute", right: 20, top: 20, zIndex: 1 }}>
            <NavigationControl zoomOutLabel="축소" zoomInLabel="확대" />
          </div> */}
        </DeckGL>
      </Container>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    dabangMapLoaded: state.dabangMapLoaded,
    dabangDeliveryCurrentTime: state.dabangDeliveryCurrentTime,
    dabangDeliveryName: state.dabangDeliveryName
  };
}

export default connect(mapStateToProps)(DabangMapContainer);