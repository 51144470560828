import React, { Component } from 'react'
import DeckGL from '@deck.gl/react';
import {ArcLayer} from '@deck.gl/layers';
import {TripsLayer} from '@deck.gl/geo-layers';
import {InteractiveMap, NavigationControl, _MapContext as MapContext, Marker } from 'react-map-gl';
import {MapController, LinearInterpolator}  from 'deck.gl';
import { changeSocialCapitalMapLoaded, changeCurrentSelectedBusiness, changeCurrentArtworksTime, changeCurrentIndividualArtistTime } from '../actions';
import { SocialCapitalLegend } from './';
import {BrushingExtension} from '@deck.gl/extensions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import GL from '@luma.gl/constants';
import { setColor } from '../utils';
import { MobileMapTransparentBlocker } from '../stylesheets/components';
import { API_URL, SC_STEP_ZOOM } from '../constants/defaults';
import { Legend, OrgNetworkSelectBox, ArtworkSelectBox } from './';
import gsap from "gsap";
import { centroid } from '@turf/turf';
import * as d3 from 'd3';
import {MobileMapToggleArea } from './';
// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;


// Viewport settings
const INITIAL_VIEW_STATE = {
  ...SC_STEP_ZOOM[0],
  minZoom: 2
}

const Container = styled.div`
  width: 100vw;
  
  height: 100vh;
  position: sticky;
  top: 0;
`;

// Data to be used by the LineLayer
const data = [
  {sourcePosition: [-122.41669, 37.7853], targetPosition: [-122.41669, 37.781]}
];

const VideoWrapper = styled.div`
  border: 3px solid #56BCDE;
  border-radius: 15px;
  background: #56BCDE;
  overflow: hidden;
  display: flex;
`


class SocialCapitalMapContainer extends Component {
  constructor(props){
    super(props);
    this._staticMapRef = React.createRef();
    this.state = {
      viewState: { ...INITIAL_VIEW_STATE },
      selectedAlley: null
    };

    this.colorScale = d3.scaleOrdinal(d3.schemeCategory10);
    this.alleyHoveredId = null;
  }


  componentDidMount(){
    const waiting = () => {
      let map = this._staticMapRef.current;
      try {

        if (map) {
          let m = map.getMap();
          if (m) {
  
            window.socialCapitalMap = m;
            this.map = m;
  
            if (this.map.isStyleLoaded()) {
              console.log("social capital loaded");
              this.addLayers();
              this.props.dispatch(changeSocialCapitalMapLoaded(true));
            } else {
              console.log("not loaded");
              setTimeout(waiting, 200);
            }
          } 
        } else {
          console.log("not loaded");
          setTimeout(waiting, 200);
        }
       
      } catch (e) {
        console.log(e);
        setTimeout(waiting, 200);
      }
    
    };

    waiting();

    this.setUpTween();
  }
  componentWillUnmount(){
    this.props.dispatch(changeSocialCapitalMapLoaded(false));
  }

  setUpTween(){
    var obj = {time: 0};
    this.tween = gsap.to(obj, {time: 80, ease: "none", duration: 50, onUpdate: (e) => {
      this.props.dispatch(changeCurrentArtworksTime(obj.time));
    }, onComplete: (e) => {

    }});
    this.tween.pause(0);


    var artistObj = {time: 0};
    this.indivArtisttween = gsap.to(artistObj, {time: 20, ease: "none", duration: 30, onUpdate: (e) => {

      this.props.dispatch(changeCurrentIndividualArtistTime(artistObj.time));
    }, onComplete: (e) => {

    }});
    this.indivArtisttween.pause(0);
  }

  componentDidUpdate(prevProps){
    // console.log(prevProps.step, this.props.step);

    if (this.props.socialCapitalMapLoaded) {
      if (this.props.step !== prevProps.step) {
        this.updateStep(this.props.step);
        this.setState({
          selectedAlley: null
        })
      }
    } 
    

    let prevViewStateByStep = SC_STEP_ZOOM[prevProps.step];
    let currentViewStateByStep = SC_STEP_ZOOM[this.props.step];


    if (prevViewStateByStep.mode !== "artworks" && currentViewStateByStep.mode === "artworks"){
  
      this.tween.pause(0);
      this.tween.play();
    } else if (prevViewStateByStep.mode === "artworks" && currentViewStateByStep.mode !== "artworks") {

      this.tween.pause(0);
    }

    if (prevProps.currentArtistId !== this.props.currentArtistId) {
      console.log(prevProps.currentArtistId, this.props.currentArtistId)
      this.loadArtistPath();
      
    } 
    
    if (prevViewStateByStep.mode === "artwork_show" && currentViewStateByStep.mode !== "artwork_show") {
      
      this.indivArtisttween.pause(0);

    }
  }

  async loadArtistPath(){
    if (this.props.currentArtistId) {
      let filename = `${API_URL}/api/artists/${this.props.currentArtistId}.json`;
        
      this.map.removeLayer('businesses-circle-layer-artists');
      this.map.removeLayer('businesses-text-layer-artists')
      this.map.removeSource("businesses_by_artists");
      this.map.addSource('businesses_by_artists', {
        "type": 'vector',
        "tiles": [`${API_URL}/tiles/businesses/artist/${this.props.currentArtistId}/{z}/{x}/{y}.pbf`],
        "minZoom": 15,
        "maxZoom": 22
      });
      
      this.map.addLayer({
        'id': 'businesses-circle-layer-artists',
        'type': 'circle',
        'source': 'businesses_by_artists',
        'source-layer': 'public.businesses',
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'circle-opacity': 0.7,
          'circle-color': [
            'case',
            ["==", ['get', 'category'], "제조"],
            '#B7F30C',
            ["==", ['get', 'category'], "유통"],
            '#0CCAF3',
            '#999'
          ],
          'circle-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            2,
            16,
            2,
            18,
            4.5,
            22,
            8
          ],
        }
      });
  
      this.map.addLayer({
        'id': 'businesses-text-layer-artists',
        'type': 'symbol',
        'source': 'businesses_by_artists',
        'source-layer': 'public.businesses',
        'layout': {
          'visibility': 'none',
          'text-allow-overlap': true,
          "text-offset": [0, -1.5],
          "text-letter-spacing": -0.1,
          "text-size": [
            'interpolate',
            ['linear'],
            ['zoom'],
            7,
            10,
            16,
            10,
            18,
            12,
            22,
            16
          ],
          "text-field": ['get', 'name'],
          "text-font": ["Noto Sans KR Medium"]
        },
        'paint': {
          "text-color": "#FFFFFF",
          "text-opacity": 1
        }
      });
      
      this.map.setLayoutProperty("businesses-text-layer-artists", "visibility", "visible");
      this.map.setLayoutProperty("businesses-circle-layer-artists", "visibility", "visible");
      this.map.setLayoutProperty("businesses-text-layer", "visibility", "none");
      this.map.setLayoutProperty("businesses-circle-layer", "visibility", "none");


      const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
      const response = await fetch(filename, { method: "get", headers });
      if (response.ok) {
        let data = await response.json();
        this.map.getSource("artist_path").setData(data);

        this.indivArtisttween.pause(0);
        this.indivArtisttween.play();
        
        
      } else {
        let data = await response.json();  
        return data;
  
      }
    } else {

      this.map.setLayoutProperty("businesses-text-layer-artists", "visibility", "none");
      this.map.setLayoutProperty("businesses-circle-layer-artists", "visibility", "none");
      this.map.setLayoutProperty("businesses-text-layer", "visibility", "visible");
      this.map.setLayoutProperty("businesses-circle-layer", "visibility", "visible");

      this.map.getSource("artist_path").setData({
        "type": "FeatureCollection",
        "features": []
      });
    }
  }

  updateStep(step) {
    // console.log(step, "step entered");
    switch(step) {
      case 0:
        this.map.setPaintProperty('alleys_layer', 'line-opacity', 0);

        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 0);
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 0);
        break;
      case 1:
        this.map.setPaintProperty('alleys_layer', 'line-opacity', 1);

        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 0);
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 0);
        break;
      case 2:
        this.map.setPaintProperty('alleys_layer', 'line-opacity', 0);
        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 1);
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 1);
        break;
      case 3:
        this.map.setPaintProperty('alleys_layer', 'line-opacity', 0);

        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 1);
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 1);
        break;
      case 4:
        this.map.setPaintProperty('alleys_layer', 'line-opacity', 0.2);

        this.map.removeLayer('businesses-circle-layer');
        this.map.removeLayer('businesses-text-layer');
        
        this.map.addLayer({
          'id': 'businesses-circle-layer',
          'type': 'circle',
          'source': 'businesses',
          'source-layer': 'public.businesses',
          'layout': {
            'visibility': 'visible',
          },
          'paint': {
            'circle-opacity': 0.7,
            'circle-color': [
              'case',
              ["==", ['get', 'category'], "제조"],
              '#B7F30C',
              ["==", ['get', 'category'], "유통"],
              '#0CCAF3',
              '#999'
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              2,
              16,
              2,
              18,
              4.5,
              22,
              8
            ],
          }
        });
    
        this.map.addLayer({
          'id': 'businesses-text-layer',
          'type': 'symbol',
          'source': 'businesses',
          'source-layer': 'public.businesses',
          'layout': {
            'visibility': 'visible',
            "text-offset": [0, -1.5],
            "text-letter-spacing": -0.1,
            "text-size": [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              10,
              16,
              10,
              18,
              12,
              22,
              16
            ],
            "text-field": ['get', 'name'],
            "text-font": ["Noto Sans KR Medium"]
          },
          'paint': {
            "text-color": "#FFFFFF",
            "text-opacity": 1
          }
        });
        
        break;
      case 5:
        this.map.removeLayer('businesses-circle-layer');
        this.map.removeLayer('businesses-text-layer');
        
        this.map.addLayer({
          'id': 'businesses-circle-layer',
          'type': 'circle',
          'source': 'businesses_all_artists',
          'source-layer': 'public.businesses',
          'layout': {
            'visibility': 'visible',
          },
          'paint': {
            'circle-opacity': 0.7,
            'circle-color': [
              'case',
              ["==", ['get', 'category'], "제조"],
              '#B7F30C',
              ["==", ['get', 'category'], "유통"],
              '#0CCAF3',
              '#999'
            ],
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              2,
              16,
              2,
              18,
              4.5,
              22,
              8
            ],
          }
        });
    
        this.map.addLayer({
          'id': 'businesses-text-layer',
          'type': 'symbol',
          'source': 'businesses_all_artists',
          'source-layer': 'public.businesses',
          'layout': {
            'visibility': 'visible',
            "text-offset": [0, -1.5],
            "text-letter-spacing": -0.1,
            "text-size": [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              10,
              16,
              10,
              18,
              12,
              22,
              16
            ],
            "text-field": ['get', 'name'],
            "text-font": ["Noto Sans KR Medium"]
          },
          'paint': {
            "text-color": "#FFFFFF",
            "text-opacity": 1
          }
        });
        
        break;
    }
  }

  setLayerSource (layerId, source, sourceLayer) {
    const oldLayers = this.map.getStyle().layers;
    const layerIndex = oldLayers.findIndex(l => l.id === layerId);
    const layerDef = oldLayers[layerIndex];
    const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
    layerDef.source = source;
    if (sourceLayer) {
        layerDef['source-layer'] = sourceLayer;
    }
    this.map.removeLayer(layerId);
    this.map.addLayer(layerDef, before);
  }


  addLayers(){

    this.map.addSource('building_footprints', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/building_footprints/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('big_roads', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/big_roads/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('alleys', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/alleys/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

   
    this.map.addSource('businesses', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('businesses_by_artists', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/artist/78/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('businesses_all_artists', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/artists/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });
    
    

   
    this.map.addSource('artist_path', {
      type: 'geojson',
      data: {
        "type": "FeatureCollection",
        "features": []
      }
    });




    this.map.addLayer({
      'id': 'big_roads_layer',
      'type': 'line',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      'layout': {},
      'paint': {
        'line-color': '#222',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          19
        ],
        'line-opacity': 1
      }
    });

    this.map.addLayer({
      'id': 'artist_path_layer',
      'type': 'line',
      'source': 'artist_path',
      'layout': {},
      'paint': {
        'line-color': '#B7F30C',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          4
        ],
        'line-opacity': 0.2
      }
    });


    this.map.addLayer({
      'id': 'building_footprints_layer',
      'type': 'fill',
      'source': 'building_footprints',
      'source-layer': 'public.building_footprints',
      'layout': {},
      'paint': {
        'fill-color': '#333',
        'fill-opacity': 0.7,
        'fill-outline-color': '#444'
      }
    });
    this.map.addLayer({
      'id': 'big_roads_text_layer',
      'type': 'symbol',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      "layout": {
        "symbol-placement": "line-center",
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"],
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          5,
          18,
          8,
          22,
          15
        ],
      },
      'paint': {
        'text-color': "#555555"
      }
    });



    this.map.addLayer({
      'id': 'alleys_layer',
      'type': 'line',
      'source': 'alleys',
      'source-layer': 'public.alleys',
      'layout': {},
      'paint': {
        'line-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false], "#FF0000",
          ['boolean', ['to-boolean', ['get', 'vr_url']], false], '#56BCDE',
          '#EEEEEE'
        ],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          2,
          18,
          5
        ],
        'line-opacity': 0
      }
    });


    this.map.addLayer({
      'id': 'businesses-circle-layer-artists',
      'type': 'circle',
      'source': 'businesses_by_artists',
      'source-layer': 'public.businesses',
      'layout': {
        'visibility': 'none',
      },
      'paint': {
        'circle-opacity': 0.7,
        'circle-color': [
          'case',
          ["==", ['get', 'category'], "제조"],
          '#B7F30C',
          ["==", ['get', 'category'], "유통"],
          '#0CCAF3',
          '#999'
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    this.map.addLayer({
      'id': 'businesses-text-layer-artists',
      'type': 'symbol',
      'source': 'businesses_by_artists',
      'source-layer': 'public.businesses',
      'layout': {
        'visibility': 'none',
        "text-offset": [0, -1.5],
        "text-letter-spacing": -0.1,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"]
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": 1
      }
    });

    this.map.addLayer({
      'id': 'businesses-circle-layer',
      'type': 'circle',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {
        "visibility": "visible"
      },
      'paint': {
        'circle-opacity': 0,
        'circle-color': [
          'case',
          ["==", ['get', 'category'], "제조"],
          '#B7F30C',
          ["==", ['get', 'category'], "유통"],
          '#0CCAF3',
          '#999'
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    this.map.addLayer({
      'id': 'businesses-text-layer',
      'type': 'symbol',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {
        "visibility": "visible",
        "text-offset": [0, -1.5],
        "text-letter-spacing": -0.1,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"]
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": 0
      }
    });
  }


  getTooltip({object}){
    return object && object.properties.name;
  }
  _onHover = event => {
    if (this.map) {
      if (this.map.isStyleLoaded()) {

        const features = this.map.queryRenderedFeatures([event.x, event.y], {
          layers: ["alleys_layer"]
        });
        
        if (features.length > 0) {
          
          if (features[0].properties.vr_url) {
            if (this.alleyHoveredId) {
              this.map.setFeatureState(
                { source: 'alleys', sourceLayer: "public.alleys", id: this.alleyHoveredId },
                { hover: false }
              );
            }
    
            this.alleyHoveredId = features[0].id;
            // console.log("found id", features[0]);
            this.map.setFeatureState(
              { source: 'alleys', sourceLayer: "public.alleys", id: this.alleyHoveredId },
              { hover: true }
            );
          }
          
        } else {
          if (this.alleyHoveredId) {
            this.map.setFeatureState(
              { source: 'alleys', sourceLayer: "public.alleys", id: this.alleyHoveredId },
              { hover: false }
            );
            this.alleyHoveredId = null;
          }
        }
      }
    }
    
  }
  _onClick = event => {
    // event.x and event.y hold the clicked x and y coordinates in pixels
    // You can pass those coordinates to React Map GL's queryRenderedFeatures
    // to query any desired layers rendered there.
    // Make sure you create the ref on InteractiveMap or StaticMap
    const features = this.map.queryRenderedFeatures([event.x, event.y], {
      layers: ["alleys_layer"]
    });
    
    if (features.length > 0) {
      // if (this.props.step === 3) {
      //   let feature = features[0];
      //   if (feature.properties.poopcoin_participants) {
      //     if (feature.properties.poopcoin_url) {
      //       window.open(feature.properties.poopcoin_url, '_blank');
      //     }
      //   }
      // } else if (this.props.step >= 0 && this.props.step <= 2) {
      //   let feature = features[0];
      //   this.props.dispatch(changeCurrentSelectedBusiness(feature.properties));
      // }
      
        let feature = features[0];
        if (feature.properties.vr_url) {
          let centroidPoint = centroid(feature.geometry);
          this.setState({
            selectedAlley: {
              id: feature.id,
              coordinates: centroidPoint.geometry.coordinates,
              vr_url: feature.properties.vr_url
            }
          })
        }
        
        // window.open(`/admin/alley/${feature.id}/edit`, '_blank');

    } else {
      this.setState({
        selectedAlley: null
      })
    }
  }


  _onViewStateChange = viewState => {
    this.setState({ viewState });
  }

  renderLayers(){
    
    let { step, orgNetworksData, artworksData, currentArtworksTime, currentIndividualArtistTime, organizationsData, currentOrgId, currentArtistId } = this.props;
    
    let layers = [];
    let viewStateByStep = SC_STEP_ZOOM[step];

    if (!(viewStateByStep.mode === "orgNetworks" || viewStateByStep.mode === "artworks" || viewStateByStep.mode === "artwork_show" )) {
      return [];
    }



    if (viewStateByStep.mode === "orgNetworks"){ 

      let selectedOrgValue;
      let selectedOrgNodeId;
      
      if (!currentOrgId || viewStateByStep.orgId === currentOrgId) {
  
        selectedOrgValue = viewStateByStep.orgId;
      
      } else {
        selectedOrgValue = currentOrgId;
        
  
      }


        
      let orgDatumFound = _.find(organizationsData, orgDatum => {
        return orgDatum.id === selectedOrgValue;
      })

      if (orgDatumFound) {
        selectedOrgNodeId = orgDatumFound.node_id;
      }
      
      
      if (!selectedOrgNodeId){
        selectedOrgNodeId = "all"
      } else {
        orgNetworksData = _.filter(orgNetworksData, datum => {
          return datum.tid === selectedOrgNodeId;
        });
      }
      
      layers = [
        new ArcLayer({
          id: `orgNetworks_${selectedOrgNodeId}`,
          parameters: {
            // prevent flicker from z-fighting
            [GL.DEPTH_TEST]: false,
        
            // turn on additive blending to make them look more glowy
            [GL.BLEND]: true,
            [GL.BLEND_SRC_RGB]: GL.ONE,
            [GL.BLEND_DST_RGB]: GL.ONE,
            [GL.BLEND_EQUATION]: GL.FUNC_ADD,
          },
          data: orgNetworksData,
          // brushingEnabled: true,
          // brushingRadius: 20,
          // brushingTarget: 'source_target',
          // extensions: [new BrushingExtension()],
          getWidth: d => {
            
            // if (selectedOrgNodeId) {
            //   return selectedOrgNodeId === d.tid ? 3 : 0.2;
            // } else {
            //   return 1;
            // }

            return 2;
          },
          getSourcePosition: d => [d.slng, d.slat],
          getTargetPosition: d => [d.tlng, d.tlat],
          getSourceColor: d => {
            return setColor(d.scategory, "rgba", 70);
          },
          getTargetColor: d => {
            return setColor(d.tcategory, "rgba", 70);
          }
        })
      ];
    } else if (viewStateByStep.mode === "artworks") {
      layers = [
        new TripsLayer({
          id: 'artworkMovements',
          data: artworksData,
          getPath: d => d.trajectories_json.path,
          getTimestamps: d => d.trajectories_json.timestamps,
          getColor: d => {
            let rgb = d3.rgb(d3.schemeTableau10[d.id % 10]);
            return [rgb.r, rgb.g, rgb.b];
          },
          opacity: 0.4,
          widthMinPixels: 2,
          rounded: true,
          trailLength: 1,
          currentTime: currentArtworksTime,
          shadowEnabled: false
        })
      ];
    } else if (viewStateByStep.mode === "artwork_show") {
      if (currentArtistId) {
        artworksData = [_.find(artworksData, ad => ad.artist_id === currentArtistId)];

        if (artworksData.length > 0) {

          layers = [
            new TripsLayer({
              id: 'artworkMovement',
              data: artworksData,
              getPath: d => d.trajectories_json.path,
              getTimestamps: d => d.trajectories_json.timestamps,
              getColor: d => {
                let rgb = d3.rgb(d3.schemeTableau10[d.id % 10]);
                return [rgb.r, rgb.g, rgb.b];
              },
              opacity: 0.8,
              widthMinPixels: 2,
              rounded: true,
              trailLength: 1,
              currentTime: currentIndividualArtistTime,
              shadowEnabled: false
            })
          ];
        }

      }
    }
      
    return layers;
  }




  render() {
    let mapStyle = process.env.MAPBOX_STYLE;
    let { selectedAlley } = this.state;
    let { windowWidth, socialCapitalMapLoaded, step, currentOrgId, windowHeight } = this.props;
    let viewStateByStep = SC_STEP_ZOOM[step];
    if (!(!currentOrgId || viewStateByStep.orgId === currentOrgId) && viewStateByStep.mode === "orgNetworks") {
      
      viewStateByStep = _.find(SC_STEP_ZOOM, steps => {
        return steps.orgId === currentOrgId
      });

      if (_.isUndefined(viewStateByStep)) {
        viewStateByStep = SC_STEP_ZOOM[2];
      }
    }
    
    var supportsTouch = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||  (navigator.msMaxTouchPoints > 0));

    return (
      <Container style={{ height: windowHeight }}>

        {
          supportsTouch ? 
          (
            this.props.touchInteractiveEnabled ? null : <MobileMapTransparentBlocker />
          )
          : null  
        }
        
        <DeckGL
          ContextProvider={MapContext.Provider}
          layers={socialCapitalMapLoaded ? this.renderLayers() : []}
          initialViewState={{
            ...viewStateByStep,
            zoom: viewStateByStep.zoomScale(windowWidth)
          }}
          // viewState={viewStateByStep}
          onViewStateChange={this._onViewStateChange}
          onClick={this._onClick}   
          onHover={this._onHover}
          // controller={true}   
          getCursor={() => "pointer"}    
          controller={{type: MapController, dragPan: this.props.touchInteractiveEnabled, touchZoom: this.props.touchInteractiveEnabled, touchRotate: this.props.touchInteractiveEnabled, doubleClickZoom: this.props.touchInteractiveEnabled, scrollZoom: this.props.touchInteractiveEnabled}}
        >
          <InteractiveMap 
            reuseMaps
            ref={this._staticMapRef }
            mapStyle={mapStyle}
            preventStyleDiffing={true}
            transitionDuration={10000} transitionInterpolator={new LinearInterpolator()}
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN} />
          <div style={{ position: "absolute", right: 20, top: 70, zIndex: 1 }}>
            <NavigationControl zoomOutLabel="축소" zoomInLabel="확대" />
          </div>
          {
            selectedAlley && step === 1 ? 
            <Marker 
              key={selectedAlley.id}
              latitude={selectedAlley.coordinates[1]} 
              longitude={selectedAlley.coordinates[0]}

              offsetLeft={-150}
              offsetTop={-320}
            > 
              <VideoWrapper>
                <iframe width="300" height="300" src={`${selectedAlley.vr_url}?rel=0&autoplay=1&mute=1`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </VideoWrapper>

            </Marker> : null
          }
        </DeckGL>
        { 
          viewStateByStep.mode === "orgNetworks" ?
          <OrgNetworkSelectBox step={step} /> : null
        }
        { 
          viewStateByStep.mode === "artwork_show" ?
          <ArtworkSelectBox step={step} /> : null
        }
        <SocialCapitalLegend step={step} />
        <MobileMapToggleArea touchInteractiveEnabled={this.props.touchInteractiveEnabled} handleTouchInteractiveEnabled={this.props.handleTouchInteractiveEnabled}/>
      </Container>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    socialCapitalMapLoaded: state.socialCapitalMapLoaded,
    businessesData: state.businessesData,
    orgNetworksData: state.orgNetworksData,
    artworksData: state.artworksData,
    currentOrgId: state.currentOrgId,
    organizationsData: state.organizationsData,
    currentArtworksTime: state.currentArtworksTime,
    currentArtistId: state.currentArtistId,
    currentIndividualArtistTime: state.currentIndividualArtistTime
  };
}

export default connect(mapStateToProps)(SocialCapitalMapContainer);