import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { changeDabangDeliveryAnimationStatus, changeDabangDeliveryCurrentTime, changeDabangDeliveryName} from '../actions';
import { Gutter } from '../stylesheets/components';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import pause_btn from '../assets/pause-btn.svg';
import play_btn from '../assets/play-btn.svg';
import gsap from "gsap";
import { map_range } from '../utils';
import moment from 'moment';
import Select from 'react-select';
import media from '../stylesheets/media';

const GraphContainer = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index:5;
  padding: 10px;
  width: 250px;
  border:1px solid #222;
  border-radius: 5px;
  background: black;



  ${media.mobileLarge`
    left:5px;
    bottom:5px;
    top:inherit;
    width: 230px;
    padding: 15px;
  `}
`;

const Title = styled.h3`
  font-size:1.0em;
  line-height:1.4;
  font-weight: 600;
`;

const SubTitle = styled.h4`
  font-size:1.1em;
  font-weight: 400;
  color: #666;
`;


const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      transition: "none",
      padding: "0px 10px 0 10px",
      borderRadius: 16,
      backgroundColor: "black",
      border: '1px solid #444',
      lineHeight: 1,
      minHeight: 32
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    border: "none",
    marginLeft: 0,
    borderRadius: 0
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none"
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      borderRadius: 16,
      padding: 5,
      margin: 0,
      zIndex: 999,
      width: 250,
      top: "calc(100% + 8px)",
      overflow: "hidden",
      boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
      backgroundColor: "white",
      color: "black"
    }
  },
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    paddingLeft: 11,
    backgroundImage: `linear-gradient(to right, black 25%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: "bottom",
    backgroundSize: "4px 1px",
    backgroundRepeat: "repeat-x",                    
    '&:hover, &:active': {
      backgroundColor: "white"
    },
    backgroundColor: "white",
    color: "black",
    "&:first-of-type": {
      paddingTop: 0
    },  
    "&:last-child": {
      backgroundImage: "none",
      paddingBottom: 0,
      marginBottom: 0
    }
  })
};
const CustomSlider = withStyles({
  thumb: {
    backgroundColor: '#fff'
  },
  valueLabel: {
    top: -22,
    '& *': {
      background: 'transparent',
      color: 'white',
    },
  },
  track: {
    background: "#FFF"
  },
  rail: {
    background: "rgba(255, 255, 255, 0.5)"
  },
})(Slider);

const SliderLabelArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom: 10px;

  div.label {
    font-size:1.0em;
  }
`;

const options = [
  { label: "T다방 사장님의 식수 배달길", value: "time"},
  { label: "S다방 사장님의 식수 배달길", value: "sujung"},
];

const PaddingArea = styled.div`
  padding: 0 20px;
`

const TimePlayArea = styled.div`
  div.label {
    font-size: 0.9em;
    font-family: "Source Sans Pro";
    color: #000;
  }


  div.time-area {
    display: flex;
    align-items: center;
  }

  div.time-label {
    font-family: 'Noto Sans KR';
    font-size: 1.3em;
    line-height: 1.0;
  }

  div.border {
    border-right: 1px solid #333;
    height: 15px;
    width:1px;
    margin: 0 10px 0 6px;
    margin-top: 3px
  }

  a.btn {
    margin-top: 5px;

    ${media.mobileSmall`
      margin-top: 0;
    `}
  }
`;

class DabangSlider extends Component {
  componentDidMount(){
    this.setUpTween();
  }
  handlePlay(e){
    e.preventDefault();
    this.props.dispatch(changeDabangDeliveryAnimationStatus("play"));
    this.tween.play();
  }

  handlePause(e){
    e.preventDefault();
    this.props.dispatch(changeDabangDeliveryAnimationStatus("pause"));
    this.tween.pause();

  }

  handleSliderChange(e, value){
    console.log(value);
    this.tween.pause(value);
    this.props.dispatch(changeDabangDeliveryAnimationStatus("pause"));
    this.props.dispatch(changeDabangDeliveryCurrentTime(value));
  }



  setUpTween(){
    var obj = {time: 0};
    this.tween = gsap.to(obj, {time: 50, ease: "none", duration: 50, onUpdate: (e) => {
      this.props.dispatch(changeDabangDeliveryCurrentTime(obj.time));
    }, onComplete: (e) => {

    }});
    this.tween.pause(0);
  }
  handleChange(e){
    this.props.dispatch(changeDabangDeliveryName(e.value));
    this.tween.pause(0);
    this.props.dispatch(changeDabangDeliveryCurrentTime(0));
    this.props.dispatch(changeDabangDeliveryAnimationStatus("pause"));
  }
  render() {
    let { dabangDeliveryName, dabangDeliveryCurrentTime, dabangDeliveryAnimationStatus } = this.props;
    

    let timeEstimate;

    if (dabangDeliveryName === "time")  {
      timeEstimate = moment().hour(8).minute(34).add(map_range(dabangDeliveryCurrentTime, 0, 50, 0, 30), 'minutes').format('hh:mm A');
    } else {
      timeEstimate = moment().hour(7).minute(30).add(map_range(dabangDeliveryCurrentTime, 0, 50, 0, 45), 'minutes').format('hh:mm A');
    }

    let selectedValue = _.find(options, option => option.value === dabangDeliveryName);
    
    return (
      <GraphContainer>
        <Select 
          options={options} 
          isSearchable={false}
          value={selectedValue}
          onChange={this.handleChange.bind(this)}
          styles={selectStyles} />
        <Gutter h={10} />
        <PaddingArea>
          <TimePlayArea>
            <div className="time-area">
              <div className="time-label">
                {/* { dabangDeliveryCurrentTime } */}
                { timeEstimate  }
              </div>

              <div className="border"></div>
              {
                dabangDeliveryAnimationStatus === "pause" || dabangDeliveryAnimationStatus === "stop" ? 
                <a href="#" className="btn" onClick={this.handlePlay.bind(this)}>
                  <img src={play_btn} alt="play" />
                </a> :
                <a href="#" className="btn" onClick={this.handlePause.bind(this)}>
                  <img src={pause_btn} alt="pause" />
                </a> 
              }
                
            </div> 
          
          </TimePlayArea>
          <Gutter h={5} />
          <CustomSlider
            defaultValue={0}
            step={ 0.00001 }
            value={dabangDeliveryCurrentTime}
            aria-labelledby="discrete-slider-always"
            onChange={this.handleSliderChange.bind(this)}
            min={ 0 }
            max={ 50 }
            valueLabelDisplay="off"
          />
        </PaddingArea>
        
       
      </GraphContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    dabangDeliveryAnimationStatus: state.dabangDeliveryAnimationStatus,
    dabangDeliveryCurrentTime: state.dabangDeliveryCurrentTime,
    dabangDeliveryName: state.dabangDeliveryName
  };
}

export default connect(mapStateToProps)(DabangSlider);