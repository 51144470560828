import React, { Component } from 'react'
import styled from 'styled-components'
import media from '../stylesheets/media';

const LegendContainer = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 5;

  ${media.mobileLarge`
    right: inherit;
    left: 20px;
  `}
`

export default class Legend extends Component {
  render() {
    let { step } = this.props;
    return (
      <LegendContainer>
        {
          step <= 2 ?
          <svg width="202" height="128" viewBox="0 0 202 128" fill="none">
            <rect x="0.5" y="0.5" width="201" height="127" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="14" y="26.168">카테고리</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="95" y="26.168">연결 관계</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="53.796">제조</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="84" y="107.796">제조</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="173" y="107.796">유통</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="78.796">유통</tspan></text>
            <text fill="white" stroke="black" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="104.796">기타</tspan></text>
            <circle cx="24" cy="50" r="8" fill="#B7F30C"/>
            <circle cx="95" cy="89" r="4" fill="#B7F30C"/>
            <circle cx="24" cy="75" r="8" fill="#0CCAF3"/>
            <circle cx="184" cy="89" r="4" fill="#0CCAF3"/>
            <circle cx="24" cy="101" r="8" fill="#555555"/>
            <path d="M94.9999 88.5C94.8332 73.6667 103.5 44 139.5 44C175.5 44 184.167 73.6667 184 88.5" stroke="url(#paint0_linear)" strokeWidth="2"/>
            <defs>
            <linearGradient id="paint0_linear" x1="184" y1="89" x2="95" y2="89" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0CCAF3"/>
            <stop offset="1" stopColor="#B7F30C"/>
            </linearGradient>
            </defs>
          </svg> : null 
        }
        {
          step === 3 ? 
          <svg width="202" height="78" viewBox="0 0 202 78" fill="none">
            <rect x="0.5" y="0.5" width="201" height="77" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="14" y="26.168">&#xb625;&#xbcf8;&#xc704; &#xd654;&#xd3d0; &#xc5c5;&#xccb4;</tspan></text>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="39" y="53.796">&#xb625;&#xbcf8;&#xc704; &#xd654;&#xd3d0; &#xcc38;&#xc5ec;&#xc5c5;&#xccb4;</tspan></text>
            <circle cx="24" cy="50" r="8" fill="#F30732"/>
          </svg> : null
        }
        {
          step === 4 ? 
          <svg width="202" height="78" viewBox="0 0 202 78" fill="none">
            <rect x="0.5" y="0.5" width="201" height="77" fill="black" stroke="#404040"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="13" fontWeight="900" letterSpacing="0em"><tspan x="14" y="26.168">&#xc744;&#xc9c0;&#xb85c; &#xc18d;&#xace8;&#xbaa9;</tspan></text>
            <path d="M15 50H46" stroke="#56BCDE" stroke-width="4"/>
            <text fill="white" fontFamily="Noto Sans KR" fontSize="11" fontWeight="500" letterSpacing="0em"><tspan x="52" y="53.796">&#xc18d;&#xace8;&#xbaa9;</tspan></text>
          </svg> : null
        }
        
      </LegendContainer>
    )
  }
}
