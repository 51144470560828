import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { Gutter } from '../stylesheets/components';
import { Header, Hero, SocialCapitalPart, IndustryNetworkPart, Credits, LightBox, DabangPart } from '../components';

const Fragment = React.Fragment;

class FirstPage extends Component {
  constructor(props){
    super(props);
  }
  componentDidMount(){
    this.loadData();
  }

  async loadData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    // // this.props.dispatch(changeProgress(true));
    // const response = await fetch("/api/words.json", { method: "get", headers });
    // if (response.ok) {
    //   let data = await response.json();
    //   this.props.dispatch(updateWords(data.words));

    // } else {
    //   // let data = await response.json();
    //   console.log(response);
    // }
  }

  render() {
    let { lightBoxPhoto } = this.props;
    return (
      <Fragment>
        <a name="top"></a>
        <Header />
        <Hero />
        <IndustryNetworkPart />
        <Gutter h={100} />
        <SocialCapitalPart />
        <Gutter h={200} />
        <DabangPart />
        {
          lightBoxPhoto ? 
          <LightBox /> : null
        }
        <Credits />
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    lightBoxPhoto: state.lightBoxPhoto
  }
}

export default withRouter(connect(mapStateToProps)(FirstPage));