import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { changeCurrentSelectedBusiness } from '../actions';
import media from '../stylesheets/media';

const CloseBtn = styled.button`
  border: 1px solid #333;
  color: white;
  background: black;
  width: 120px;
  border-radius: 30px;
  padding: 7px 20px;
  font-size: 1.0em;
  line-height: 1.2;
  margin-right: 15px;
  position: sticky;
  z-index: 9999;
  top: 64px;
  left: 50%;
  cursor:pointer;
  transform:translate(-50%, 0);

  ${media.mobileLarge`
    
    left: inherit;
    right: 50px;
    transform:none;

  `}

  ${media.mobileSmall`
    
    left: 20px;
    bottom:20px;
    top:inherit;
    transform:none;

  `}
`

class BusinessShowCloseBtn extends Component {
  handleClick(){
    this.props.dispatch(changeCurrentSelectedBusiness(null));
  }
  render() {
    return (
      <CloseBtn onClick={this.handleClick.bind(this)}>
        전체 보기 ×
      </CloseBtn>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentSelectedBusiness: state.currentSelectedBusiness
  }
}
export default connect(mapStateToProps)(BusinessShowCloseBtn);
