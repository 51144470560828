import _ from 'lodash';

export const numberWithDelimiter = (number, delimiter, separator) => {
  try {
    var delimiter = delimiter || ",";
    var separator = separator || ".";
    
    var parts = number.toString().split('.');
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter);
    return parts.join(separator);
  } catch(e) {
    return number
  }
};

export const tablize = (str) => {
  return _.snakeCase(str) + "s";
}

export const setColor = (category, type, _opacity) => {
  let opacity = 50;
  if (_opacity) {
    opacity = _opacity;
  }
  
  if (category === "제조") {
    return type === "rgba" ? [183, 243, 12, opacity] : "#B7F30C";
  } else if (category === "유통") {
    return type === "rgba" ? [12, 202, 243, opacity] : "#0CCAF3";
  } else {
    return type === "rgba" ? [55, 55, 55, opacity] : "#555555";
  }
}

export const map_range = (value, low1, high1, low2, high2) => {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}