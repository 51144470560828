import React, { Component } from 'react'
import styled from 'styled-components';
import { Gutter } from '../stylesheets/components';
import media from '../stylesheets/media';

const ToggleArea = styled.div`
  position: absolute;
  bottom: 24px;
  z-index: 5;
  background-color: black;
  border: 1px solid #404040;
  padding: 10px;
  width: 118px;
  height: 105px;
  left: 20px;

  ${media.mobileLarge`
    left: 227px;
  `}
  ${media.mobileSmall`
    right: 10px;
    left:inherit;
  `}

`
const Desc = styled.div`
  color: #666;
  font-size:10px;
  font-weight: 400;
  letter-spacing: .0125em;
  line-height: 1.3;
`

const ToggleBtn = styled.button`
  display: inline-block;
  border: 1px solid white;
  color: white;
  background: transparent;
  border-radius: 30px;
  padding: 7px 15px;
  font-size: 1.0em;
  line-height:1.2;
  
  &.active {
    background: white;
    color: black;
  }
`;


class MobileMapToggleArea extends Component {
  render() {
    let { touchInteractiveEnabled } = this.props;
    var supportsTouch = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||  (navigator.msMaxTouchPoints > 0));
    return (
      <ToggleArea>
        <ToggleBtn className={touchInteractiveEnabled ? "active" : ""} onClick={this.props.handleTouchInteractiveEnabled}>
          지도 조작 {touchInteractiveEnabled ? <>끄기</> : <>켜기</> } 
        </ToggleBtn>
        <Gutter h={5}/>
        {
          touchInteractiveEnabled ? 
          <Desc>
            * 계속 스크롤 하고 싶으시면 위 버튼을 눌러 지도 조작을 해제해 주세요.
          </Desc> :
          <Desc>
            * 지도 요소를 {supportsTouch ? <>클릭하거나</> : null } 확대/축소 하고 싶으시면 위 버튼을 눌러 지도 조작을 활성화시켜 주세요.
          </Desc>
        }
        
      </ToggleArea>
    )
  }
}


export default MobileMapToggleArea;