import React, { Component } from 'react'
import Select from 'react-select';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { SC_STEP_ZOOM, API_URL } from '../constants/defaults';
import { changeCurrentOrgId, changeCurrentOrgPhotos } from '../actions';
import { OrgInfoBox } from './';

const Container = styled.div`
  position: absolute;
  left: 20px;
  top: 65px;
  z-index: 5;
  width: 200px;
`;

const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      transition: "none",
      padding: "0px 10px 0 16px",
      borderRadius: 16,
      backgroundColor: "black",
      border: '1px solid #444',
      lineHeight: 1,
      minHeight: 32
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
    border: "none",
    marginLeft: 0,
    borderRadius: 0
  }),
  indicatorSeparator: (provided, state) => ({
    display: "none"
  }),
  menu: (provided, state) => {
    return {
      ...provided,
      borderRadius: 16,
      padding: 5,
      margin: 0,
      zIndex: 999,
      width: 200,
      top: "calc(100% + 8px)",
      boxShadow: "0px 4px 16px 0px rgba(0,0,0,0.75)",
      backgroundColor: "white",
      color: "black"
    }
  },
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    border: "none",
    paddingLeft: 11,
    backgroundImage: `linear-gradient(to right, black 25%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: "bottom",
    backgroundSize: "4px 1px",
    backgroundRepeat: "repeat-x",                    
    '&:hover, &:active': {
      backgroundColor: "white"
    },
    backgroundColor: "white",
    color: "black",
    "&:first-of-type": {
      paddingTop: 0
    },  
    "&:last-child": {
      backgroundImage: "none",
      paddingBottom: 0,
      marginBottom: 0
    }
  })
};


class OrgNetworkSelectBox extends Component {

  handleChange(e){
    this.loadData(e.value);
  }

  async loadData(orgId){
    let filename = `${API_URL}/api/businesses/${orgId}.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    this.props.dispatch(changeCurrentOrgPhotos([]));

    const response = await fetch(filename, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();

      this.props.dispatch(changeCurrentOrgPhotos(data.business.photo_urls));
      this.props.dispatch(changeCurrentOrgId(orgId));
      
    } else {
      let data = await response.json();

      return data;
    }
  }


  render() {
    
    let { organizationsData, currentOrgId, step } = this.props;
    let options = _.map(organizationsData, org => {
      return {
        label: org.name,
        value: org.id
      }
    })

    let selectedOrgValue;
    let viewStateByStep;

    viewStateByStep = SC_STEP_ZOOM[step];
    
    if (!currentOrgId || viewStateByStep.orgId === currentOrgId) {

      selectedOrgValue = _.find(options, option => {
        return option.value === viewStateByStep.orgId
      });
    
    } else {

      selectedOrgValue = _.find(options, option => {
        return option.value === currentOrgId
      });

    }
    


    return (
      <Container>
        <Select 
          options={options} 
          isSearchable={false}
          value={selectedOrgValue}
          onChange={this.handleChange.bind(this)}
          styles={selectStyles} />
        {
          currentOrgId ? 
          <OrgInfoBox /> : null 
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    organizationsData: state.organizationsData,
    currentOrgId: state.currentOrgId
  }
}

export default connect(mapStateToProps)(OrgNetworkSelectBox);