import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { DescContainer, Gutter, DescTitle, DescParagraph } from '../stylesheets/components';

class Credits extends Component {
  render() {
    let { windowWidth } = this.props;
    return (
      <DescContainer>
        <a name="credit-part"></a>
        <Gutter h={100} />
        <DescTitle>
          크레딧<br/>
          Credits
        </DescTitle>
        <DescParagraph>
          <b>기획 및 조사연구</b><br/>
          <a href="http://www.cheongyecheon.com/" target="_blank">청계천을지로보존연대</a>, <a href="http://www.kccs.or.kr/" target="_blank">(사)문화사회연구소</a><br/>
          박은선, 안근철, 장현욱, 정아람, 최혁규, 홍지수<br/><br/>

          <b>데이터 시각화</b><br/>
          <a href="https://wonyoung.so" target="_blank">소원영</a><br/>
          선샤인언더그라운드<br/><br/>

          <b>데이터 출처</b><br/>
          <Gutter h={10} />
          져스트프로젝트(2018), 『청년 생산활동 촉진을 위한 산업생태계 현장연구』, 청년허브.<br/>
          청계천을지로보존연대(2019), 청계천 산업생태계 조사, 미발표자료.<br/>
          걷고싶은도시만들기시민연대(2020),『예술작품 제작 과정에서 을지로 기술생태계 이용 방식에 대한 연구』, 중구문화재단.<br/>
          청계천을지로보존연대(2020), <a href="http://www.ohmynews.com/NWS_Web/iRoom/articles/news_list.aspx?MEM_CD=00772030" target="_blank">「세운재개발, 무너지는 도시생태」기획연재 시리즈</a>, 오마이뉴스.<br/>
          구가도시건축(2020), 청계천 일대 속골목 지도, 미발표자료.
        </DescParagraph>
        <Gutter h={20} />
        <DescParagraph>
          <br/><br/>
          서울시 2020 지속가능한 도시전환랩 실험 프로젝트 &lt;청계천 일대 에코팩토리 리빙랩&gt; 사업의 일환으로 제작되었습니다.
        </DescParagraph>
        <Gutter h={200} />
      </DescContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default connect(mapStateToProps)(Credits);