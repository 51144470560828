import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setColor } from '../utils';
import styled from 'styled-components';
import _ from 'lodash';
import { Gutter, BSNetworkArea,
  BSNetworkTitle,
  BSNode,
  BSNodeName } from '../stylesheets/components';
import { style } from 'd3';

const Fragment = React.Fragment;
const BSContainer = styled.div`
  margin-top: 10px;
  width: 300px;
  background: #000000;
  padding: 22px 22px;
  border-radius: 22px;
`;

const BSTitleArea = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #404040;
  padding-bottom: 5px;
  margin-bottom: 5px;
`


const BSTitle = styled.h3`
  font-weight: 900;
`
const BSCategory = styled.div`
  display:flex;
  align-items: center;
`;
const BSCategoryLabel = styled.div`
  margin-right: 5px;
`
const BSCategoryCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: white;
`

const BSDescArea = styled.div``
const BSLabel = styled.div`
  margin-bottom: 5px;
  color: #777;
  line-height: 1.5;
  
`
const BSValue = styled.div`
  font-weight: 900;
  margin-bottom: 10px;
`

const BSLabelHighLight = styled.span`
  font-weight: 900;
  color: white;
`; 


class ArtistQuestionBox extends Component {
  constructor(props){
    super(props);
    this.state = {
      artistBusinesses: []
    };
  }
  componentDidMount(){
    this.loadBusinessesData();
  }

  componentDidUpdate(prevProps){
    if (prevProps.currentArtistId !== this.props.currentArtistId) {
      this.loadBusinessesData();
    }
  }

  async loadBusinessesData(){

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }

    const response = await fetch(`/api/artists/${this.props.currentArtistId}/businesses.json`, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        artistBusinesses: data.artist_businesses
      })
      
    } else {
      let data = await response.json();

      return data;

    }
  }

  getArtist(){
    let { currentArtistId, artistsData } = this.props;

    return _.find(artistsData, ad => {
      return ad.id === currentArtistId;
    })

  }

  render() {
    let artist = this.getArtist(); 
    let { artistBusinesses } = this.state;
 
    return (
      <BSContainer>
        <BSDescArea>

          <BSTitleArea>
            <BSLabel>
              활동 분야<br/>
              <BSLabelHighLight>{ artist.field }</BSLabelHighLight>
            </BSLabel>
          </BSTitleArea>
          <Gutter h={5} />

          {
            artistBusinesses.length > 0 ?
            <>
            <BSNetworkArea>
              <BSNetworkTitle>
                거래 업체 <b>{artistBusinesses.length}</b>
              </BSNetworkTitle>

              {
                _.map(artistBusinesses, business => {

                  return (
                    <BSNode key={business.id}>
                      <BSNodeName>
                        { business.name}
                      </BSNodeName>
                      <BSCategory>
                        <BSCategoryLabel>
                          { business.category }
                        </BSCategoryLabel>
                        <BSCategoryCircle 
                          style={{ backgroundColor: setColor(business.category, "hex") }}
                        />
                      </BSCategory>
                    </BSNode>
                  )
                })
              }
            </BSNetworkArea>
            <Gutter h={10} />
            </> : null
          }

          <BSValue>
            { artist.notes }
          </BSValue>



          {/* <BSLabel>
            을지로 일대 업체를 처음으로 이용하기 시작한 연도
          </BSLabel>
          <BSValue>
            { artist.first_year }
          </BSValue>

          <BSLabel>
            예술작업을 위해 을지로 일대 업체를 처음 이용하기 시작했을 때의 이유
          </BSLabel>
          <BSValue>
            { artist.q1 }
          </BSValue>

          <BSLabel>
            예술작업을 위해 을지로 일대 업체를 계속 이용하는 이유
          </BSLabel>
          <BSValue>
            { artist.q2 }
          </BSValue> */}
        </BSDescArea>
      </BSContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentArtistId: state.currentArtistId,
    artistsData: state.artistsData
  }
}

export default connect(mapStateToProps)(ArtistQuestionBox);