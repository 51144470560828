export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const initData = (data) => {
  return {
    type: 'INIT_DATA',
    payload: {
      businessesData: data.businesses,
      organizationsData: data.organizations,
      ecosystemsData: data.ecosystems,
      orgNetworksData: data.org_networks,
      artworksData: data.artworks,
      artistsData: data.artists
    }
  }
}


export const changeSocialCapitalMapLoaded = (socialCapitalMapLoaded) => {
  return {
    type: 'CHANGE_SOCIAL_CAPITAL_MAP_LOADED',
    payload: {
      socialCapitalMapLoaded: socialCapitalMapLoaded,
    }
  }
}


export const changeMapLoaded = (mapLoaded) => {
  return {
    type: 'CHANGE_MAP_LOADED',
    payload: {
      mapLoaded: mapLoaded,
    }
  }
}

export const changeDabangMapLoaded = (dabangMapLoaded) => {
  return {
    type: "CHANGE_DABANG_MAP_LOADED",
    payload: {
      dabangMapLoaded: dabangMapLoaded
    }
  }
}


export const changeCurrentSelectedBusiness = (currentSelectedBusiness) => {
  return {
    type: 'CHANGE_CURRENT_SELECTED_BUSINESS',
    payload: {
      currentSelectedBusiness: currentSelectedBusiness
    }
  }
}

export const changeDabangDeliveryName = (dabangDeliveryName) => {
  return {
    type: 'CHANGE_DABANG_DELIVERY_NAME',
    payload: {
      dabangDeliveryName: dabangDeliveryName
    }
  }
}


export const changeCurrentArtworksTime = (currentArtworksTime) => {
  return {
    type: "CHANGE_CURRENT_ARTWORKS_TIME",
    payload: {
      currentArtworksTime: currentArtworksTime
    }
  }
}


export const changeCurrentIndividualArtistTime = (currentIndividualArtistTime) => {
  return {
    type: "CHANGE_CURRENT_INDIVIDUAL_ARTIST_TIME",
    payload: {
      currentIndividualArtistTime: currentIndividualArtistTime
    }
  }
}

export const changeLightBoxPhoto = (lightBoxPhoto) => {
  return {
    type: "CHANGE_LIGHTBOX_PHOTO",
    payload: {
      lightBoxPhoto: lightBoxPhoto
    }
  }
}


export const changeDabangDeliveryCurrentTime = (dabangDeliveryCurrentTime) => {
  return {
    type: "CHANGE_DABANG_DELIVERY_CURRENT_TIME",
    payload: {
      dabangDeliveryCurrentTime: dabangDeliveryCurrentTime
    }
  }
}


export const changeDabangDeliveryAnimationStatus = (dabangDeliveryAnimationStatus) => {
  return {
    type: "CHANGE_DABANG_DELIVERY_ANIMATION_STATUS",
    payload: {
      dabangDeliveryAnimationStatus: dabangDeliveryAnimationStatus
    }
  }
}




export const changeCurrentOrgId = (currentOrgId) => {
  return {
    type: "CHANGE_CURRENT_ORG_ID",
    payload: {
      currentOrgId: currentOrgId
    }
  }
}

export const changeCurrentArtistId = (currentArtistId) => {
  return {
    type: "CHANGE_CURRENT_ARTIST_ID",
    payload: {
      currentArtistId: currentArtistId
    }
  }
}


export const changeCurrentOrgPhotos = (currentOrgPhotos) => {
  return {
    type: "CHANGE_CURRENT_ORG_PHOTOS",
    payload: {
      currentOrgPhotos: currentOrgPhotos
    }
  }
}