import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){
  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
    case 'INIT_DATA':
      return {
        ...state,
        businessesData: action.payload.businessesData,
        ecosystemsData: action.payload.ecosystemsData,
        organizationsData: action.payload.organizationsData,
        orgNetworksData: action.payload.orgNetworksData,
        artworksData: action.payload.artworksData,
        artistsData: action.payload.artistsData
      }
    case 'CHANGE_MAP_LOADED':
      return {
        ...state,
        mapLoaded: action.payload.mapLoaded
      }
    case 'CHANGE_SOCIAL_CAPITAL_MAP_LOADED':
      return {
        ...state,
        socialCapitalMapLoaded: action.payload.socialCapitalMapLoaded
      }
    case 'CHANGE_DABANG_MAP_LOADED':
      return {
        ...state,
        dabangMapLoaded: action.payload.dabangMapLoaded
      }
    case "CHANGE_CURRENT_ARTWORKS_TIME":
      return {
        ...state,
        currentArtworksTime: action.payload.currentArtworksTime
      }
    case 'CHANGE_CURRENT_SELECTED_BUSINESS':
      return {
        ...state,
        currentSelectedBusiness: action.payload.currentSelectedBusiness
      };
    case 'CHANGE_CURRENT_ORG_ID':
      return {
        ...state,
        currentOrgId: action.payload.currentOrgId
      };
    case 'CHANGE_CURRENT_ARTIST_ID':
      return {
        ...state,
        currentArtistId: action.payload.currentArtistId
      };
    case "CHANGE_CURRENT_INDIVIDUAL_ARTIST_TIME":
      return {
        ...state,
        currentIndividualArtistTime: action.payload.currentIndividualArtistTime
      };
    case "CHANGE_CURRENT_ORG_PHOTOS":
      return {
        ...state,
        currentOrgPhotos: action.payload.currentOrgPhotos
      }
    case "CHANGE_LIGHTBOX_PHOTO":
      return {
        ...state,
        lightBoxPhoto: action.payload.lightBoxPhoto
      }
    case "CHANGE_DABANG_DELIVERY_CURRENT_TIME":
      return {
        ...state,
        dabangDeliveryCurrentTime: action.payload.dabangDeliveryCurrentTime
      };
    case "CHANGE_DABANG_DELIVERY_ANIMATION_STATUS":
      return {
        ...state,
        dabangDeliveryAnimationStatus: action.payload.dabangDeliveryAnimationStatus
      }
    case "CHANGE_DABANG_DELIVERY_NAME":
      return {
        ...state,
        dabangDeliveryName: action.payload.dabangDeliveryName
      }
    default: 
      return {
        ...state
      };
  }

};