import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import hero_images_01 from "../assets/hero_images_01.png";
import hero_images_02 from "../assets/hero_images_02.png";
import hero_images_03 from "../assets/hero_images_03.png";
import { Gutter } from '../stylesheets/components';
import media from '../stylesheets/media';

const Container = styled.div`
  height: 1150px;
  position: relative;
  overflow: hidden;

  ${media.mobileSmall`
    height: auto;
    margin-bottom: 20px;
  `}

`

const HeroTitle = styled.div`
  font-weight: 900;
  font-size:14.285em;
  text-align:center;
  line-height: 1.3;
  position: relative;
  z-index:2;


  ${media.padLandscape`
    font-size: 12em;
  `}

  ${media.mobileSmall`
    font-size: 6em;
  `}
`;

const HeroPhotoOne = styled.img`
  position: absolute;
  z-index:1;
  left: calc(50% + 140px);
  top: 20px;
  width: 462px;


  ${media.padLandscape`
    left: calc(50% + 20px);
  `}

  ${media.mobileLarge`
    left: 37%;
  `}

  ${media.mobileSmall`
    left: 40%;
    width: 200px;
  `}
`;

const HeroPhotoTwo = styled.img`
  position: absolute;
  z-index:1;
  left: calc(50% - 570px);
  top: 270px;
  width: 415px;

  ${media.padLandscape`
    left: calc(50% - 431px);
  `}


  ${media.mobileLarge`
    left: 5%;
  `}

  ${media.mobileSmall`
    top: 160px;
    width: 200px;
  `}
`;

const HeroPhotoThree = styled.img`
  position: absolute;
  z-index:1;
  left: calc(50% + 100px);
  top: 600px;
  width: 415px;

  ${media.padLandscape`
    left: calc(50% + 10px);
  `}

  ${media.mobileLarge`
    left: 40%;
  `}

  ${media.mobileSmall`
    top: 300px;
    width: 200px;
  `}
`;

class Hero extends Component {
  render() {
    let { windowHeight } = this.props;
    return (
      <Container>
        <HeroPhotoOne src={hero_images_02} />
        <HeroPhotoTwo src={hero_images_01} />
        <HeroPhotoThree src={hero_images_03} />
        <Gutter h={50} />
        <HeroTitle>
          청계천<br/>
          을지로<br/>
          산업 유통<br/>
          생태계
        </HeroTitle>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(Hero);