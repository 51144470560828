import React, { Component } from 'react'
import DeckGL from '@deck.gl/react';
import {ArcLayer} from '@deck.gl/layers';
import {InteractiveMap, NavigationControl, _MapContext as MapContext } from 'react-map-gl';
import {MapController, FlyToInterpolator, LinearInterpolator}  from 'deck.gl';
import { changeMapLoaded, changeCurrentSelectedBusiness } from '../actions';
import {BrushingExtension} from '@deck.gl/extensions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import GL from '@luma.gl/constants';
import { setColor } from '../utils';
import { API_URL } from '../constants/defaults';
import { Legend } from '.';
import { scaleLinear } from 'd3-scale';
import { MobileMapTransparentBlocker } from '../stylesheets/components'
import {MobileMapToggleArea } from './';
const transitionInterpolator = new LinearInterpolator(['longitude', 'latitude', 'zoom', 'pitch', 'bearing']);

// Set your mapbox access token here
const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN;

const STEP_ZOOM = [
  {
    longitude: 126.99468406015725,
    latitude: 37.56838388514358,
    zoomScale: scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([15, 15.5, 15.9, 16, 16.3, 16.81]),
    zoom: 16.81,
    minZoom: 15,
    maxZoom: 22,
    networkShow: false,
    pitch: 0,
    bearing: 0
  },
  {
    longitude: 126.99428096860146,  
    latitude: 37.568668054337365,
    zoomScale: scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([15, 15.5, 15.9, 16, 16.3, 16.81]),
    zoom: 16.55446,
    minZoom: 15,
    maxZoom: 22,
    networkShow: true,
    inside_euljiro: true,
    pitch: 51.83912248628885,
    bearing: 24.22248803827756
  },
  {
    longitude: 127.6658390686487,
    latitude: 36.125217690551715,
    zoomScale: scaleLinear().domain([414, 768, 1024, 1280, 1440, 2560]).range([6, 6.8, 6.9, 7.1, 7.42, 7.42]),
    zoom: 6,
    minZoom: 6,
    maxZoom: 7.42,
    networkShow: true,
    inside_euljiro: false,
    pitch: 48.81181818181813,
    bearing: 47.855
  }
]

// Viewport settings
const INITIAL_VIEW_STATE = {
  ...STEP_ZOOM[0],
  minZoom: 2
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
`;


// Data to be used by the LineLayer
const data = [
  {sourcePosition: [-122.41669, 37.7853], targetPosition: [-122.41669, 37.781]}
];

class IndustryNetworkMapContainer extends Component {
  constructor(props){
    super(props);
    this._staticMapRef = React.createRef();
    this.state = {
      viewState: { ...INITIAL_VIEW_STATE }
    };

  }


  componentDidMount(){
    const waiting = () => {
      let map = this._staticMapRef.current;
      try {

        if (map) {
          let m = map.getMap();
          if (m) {
  
            window.map = m;
            this.map = m;
  
            if (this.map.isStyleLoaded()) {
              console.log("loaded");
              this.addLayers();
              this.props.dispatch(changeMapLoaded(true));
              this.updateStep(this.props.step);
              // this.startTimer(); HAS TO BE CHANGED TO 
            } else {
              console.log("not loaded");
              setTimeout(waiting, 200);
            }
          } 
        } else {
          console.log("not loaded");
          setTimeout(waiting, 200);
        }
       
      } catch (e) {
        console.log(e);
        setTimeout(waiting, 200);
      }
    
    };

    waiting();
  }

  componentDidUpdate(prevProps){
    // console.log(prevProps.step, this.props.step);

    if (this.props.mapLoaded) {
      if (prevProps.step !== this.props.step) {
        this.updateStep(this.props.step);
      }
    }
  }

  componentWillUnmount(){
    this.props.dispatch(changeMapLoaded(false));
  }

  updateStep(step) {
    
  
    switch(step) {
      case 0: 
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 1);
        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 1);
        break;
      case 1: 
        break;
      case 2: 

        this.map.setPaintProperty(
          'businesses-circle-layer', 'circle-color', 
          [
            'case',
            ["==", ['get', 'category'], "제조"],
            '#B7F30C',
            ["==", ['get', 'category'], "유통"],
            '#0CCAF3',
            '#999'
          ]
        );

        this.map.setPaintProperty('businesses-text-layer', 'text-opacity', 1);
        this.map.setPaintProperty('businesses-circle-layer', 'circle-opacity', 1);
        this.map.setLayoutProperty('businesses-text-layer', 'text-allow-overlap', false);
        this.map.setLayoutProperty('businesses-text-layer', 'text-ignore-placement', false);
        break;

    }
  }


  addLayers(){

    this.map.addSource('building_footprints', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/building_footprints/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('big_roads', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/big_roads/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });

    this.map.addSource('alleys', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/alleys/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });


    this.map.addSource('businesses', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/businesses/{z}/{x}/{y}.pbf`],
      "minZoom": 15,
      "maxZoom": 22
    });



    this.map.addLayer({
      'id': 'big_roads_layer',
      'type': 'line',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      'layout': {},
      'paint': {
        'line-color': '#222',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          19
        ],
        'line-opacity': 1
      }
    });


    this.map.addLayer({
      'id': 'big_roads_text_layer',
      'type': 'symbol',
      'source': 'big_roads',
      'source-layer': 'public.big_roads',
      "layout": {
        "symbol-placement": "line-center",
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"],
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          5,
          18,
          8,
          22,
          15
        ],
      },
      'paint': {
        'text-color': "#555555"
      }
    });



    this.map.addLayer({
      'id': 'alleys_layer',
      'type': 'line',
      'source': 'alleys',
      'source-layer': 'public.alleys',
      'layout': {},
      'paint': {
        'line-color': '#333', //'#17BEE2',
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          16,
          0.5,
          18,
          4
        ],
        'line-opacity': 1
      }
    });



    this.map.addLayer({
      'id': 'building_footprints_layer',
      'type': 'fill-extrusion',
      'source': 'building_footprints',
      'source-layer': 'public.building_footprints',
      'layout': {},
      'paint': {
        'fill-extrusion-color': '#333',
        'fill-extrusion-height': ['get', 'height'],
        'fill-extrusion-opacity': 0.7
      }
    });


    this.map.addLayer({
      'id': 'businesses-circle-layer',
      'type': 'circle',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {},
      'paint': {
        'circle-opacity': 0.7,
        'circle-color': [
          'case',
          ["==", ['get', 'category'], "제조"],
          '#B7F30C',
          ["==", ['get', 'category'], "유통"],
          '#0CCAF3',
          '#999'
        ],
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          2,
          16,
          2,
          18,
          4.5,
          22,
          8
        ],
      }
    });

    this.map.addLayer({
      'id': 'businesses-text-layer',
      'type': 'symbol',
      'source': 'businesses',
      'source-layer': 'public.businesses',
      'layout': {
        "text-offset": [0, -1.5],
        "text-letter-spacing": -0.1,
        "text-size": [
          'interpolate',
          ['linear'],
          ['zoom'],
          7,
          10,
          16,
          10,
          18,
          12,
          22,
          16
        ],
        "text-field": ['get', 'name'],
        "text-font": ["Noto Sans KR Medium"]
      },
      'paint': {
        "text-color": "#FFFFFF",
        "text-opacity": 1
      }
    });

  }


  getTooltip({object}){
    return object && object.properties.name;
  }
  _onClick = event => {
    // event.x and event.y hold the clicked x and y coordinates in pixels
    // You can pass those coordinates to React Map GL's queryRenderedFeatures
    // to query any desired layers rendered there.
    // Make sure you create the ref on InteractiveMap or StaticMap
    const features = this.map.queryRenderedFeatures([event.x, event.y], {
      layers: ["businesses-text-layer", "businesses-circle-layer"]
    });
    
    if (features.length > 0) {
      // if (this.props.step === 3) {
      //   let feature = features[0];
      //   if (feature.properties.poopcoin_participants) {
      //     if (feature.properties.poopcoin_url) {
      //       window.open(feature.properties.poopcoin_url, '_blank');
      //     }
      //   }
      // } else if (this.props.step >= 0 && this.props.step <= 2) {
      //   let feature = features[0];
      //   this.props.dispatch(changeCurrentSelectedBusiness(feature.properties));
      // }
      
        let feature = features[0];
        this.props.dispatch(changeCurrentSelectedBusiness(feature.properties));
      

    }
  }


  _onViewStateChange = viewState => {
    this.setState({ viewState });
  }

  renderLayers(){
    
    let { step, ecosystemsData, currentSelectedBusiness } = this.props;
    
    let viewStateByStep = STEP_ZOOM[step];
    if (!viewStateByStep.networkShow) {
      return [];
    }

    let insideEuljiro = viewStateByStep.inside_euljiro;


    if (currentSelectedBusiness) {
      ecosystemsData = _.filter(ecosystemsData, ed => {
        return ed.sid === currentSelectedBusiness.node_id || 
               ed.tid === currentSelectedBusiness.node_id;
      });
      
    } else {

      if (insideEuljiro) {
        ecosystemsData = _.filter(ecosystemsData, ed => {
          return ed.inside_euljiro;
        });
      } else {
        ecosystemsData = _.filter(ecosystemsData, ed => {
          return !ed.inside_euljiro;
        });
      }

    }


    let layers = [
      new ArcLayer({
        id: 'ecosystems',
        parameters: {
          // prevent flicker from z-fighting
          [GL.DEPTH_TEST]: false,
      
          // turn on additive blending to make them look more glowy
          [GL.BLEND]: true,
          [GL.BLEND_SRC_RGB]: GL.ONE,
          [GL.BLEND_DST_RGB]: GL.ONE,
          [GL.BLEND_EQUATION]: GL.FUNC_ADD,
        },
        data: ecosystemsData,
        brushingEnabled: this.props.touchInteractiveEnabled ? (currentSelectedBusiness ? false : true) : false,
        brushingRadius: viewStateByStep.inside_euljiro ? 20 : 10000,
        brushingTarget: 'source_target',
        extensions: [new BrushingExtension()],
        getWidth: d => {
          return 0.6;
        },
        getSourcePosition: d => [d.slng, d.slat],
        getTargetPosition: d => [d.tlng, d.tlat],
        getSourceColor: d => setColor(d.scategory, "rgba"),
        getTargetColor: d => setColor(d.tcategory, "rgba")
      })
    ];
    return layers;
  }

  render() {
    let mapStyle = process.env.MAPBOX_STYLE;
    let { mapLoaded, step, windowWidth, windowHeight } = this.props;
    console.log("render in industrynetworkmapcontainer", windowHeight);
    let viewStateByStep = STEP_ZOOM[step];
    var supportsTouch = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) ||  (navigator.msMaxTouchPoints > 0));
    // console.log("render invoked", {
    //   ...viewStateByStep,
    //   zoom: viewStateByStep.zoomScale(windowWidth)
    // });
    return (
      <Container style={{ height: windowHeight }}>
        {
          supportsTouch ? 
          (
            this.props.touchInteractiveEnabled ? null : <MobileMapTransparentBlocker />
          )
          : null  
        }
        
        <DeckGL
          ContextProvider={MapContext.Provider}
          layers={mapLoaded ? this.renderLayers() : []}
          initialViewState={{
            ...viewStateByStep,
            zoom: viewStateByStep.zoomScale(windowWidth)
          }}
          // viewState={viewStateByStep}
          onViewStateChange={this._onViewStateChange}
          onClick={this._onClick}   
          // controller={true}   
          getCursor={() => "pointer"}    
          controller={{type: MapController, inertia: 200, dragPan: this.props.touchInteractiveEnabled, touchZoom: this.props.touchInteractiveEnabled, touchRotate: this.props.touchInteractiveEnabled, doubleClickZoom: this.props.touchInteractiveEnabled, scrollZoom: this.props.touchInteractiveEnabled}}  
        >
          <InteractiveMap 
            reuseMaps
            ref={this._staticMapRef }
            mapStyle={mapStyle}
            preventStyleDiffing={true}
            mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN} />
          <div style={{ position: "absolute", right: 20, top: 70, zIndex: 1 }}>
            <NavigationControl zoomOutLabel="축소" zoomInLabel="확대" />
          </div>
        </DeckGL>
        <Legend step={step} />
        <MobileMapToggleArea touchInteractiveEnabled={this.props.touchInteractiveEnabled} handleTouchInteractiveEnabled={this.props.handleTouchInteractiveEnabled}/>
      </Container>
    );
  }
}

let mapStateToProps = state => {
  return {
    mapLoaded: state.mapLoaded,
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    businessesData: state.businessesData,
    ecosystemsData: state.ecosystemsData,
    currentSelectedBusiness: state.currentSelectedBusiness
  };
}

export default connect(mapStateToProps)(IndustryNetworkMapContainer);