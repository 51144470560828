import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { SocialCapitalMapContainer } from '../components';
import { DescContainer, DescBigQuote, DescTitle, DescParagraph, Gutter, Caveat } from '../stylesheets/components';
import industry_network_photo from '../assets/industry_network_photo.png';
import soccer_image_01 from '../assets/soccer_image_01.jpg';
import soccer_image_02 from '../assets/soccer_image_02.jpg';
import sanlim_01 from '../assets/sanlim_01.jpg';
import sanlim_02 from '../assets/sanlim_02.jpg';
import "intersection-observer";
import scrollama from "scrollama";
import { changeCurrentArtistId, changeCurrentOrgId } from '../actions';
import { SC_STEP_ZOOM } from '../constants/defaults';
import { style } from 'd3';
import { Carousel } from 'react-responsive-carousel';

const Fragment = React.Fragment;
const CoverImg = styled.img`
  width: 100%;
`

const StickyContainer = styled.div`
  position:relative;
  margin-top: 50px;
  height: 10000px;
`;


const ScrollContainer = styled.div`
  margin-right: 50px;
  position:relative;
  width: 600px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const LabelImg = styled.img`
  width: 100%;
`;

const ScrollLabel = styled.div`
  width: 392px;
  z-index: 20;
  position: absolute;
  left: 20px;
  /* transform: translate(-50%, 0); */

  

  @media (max-width: 414px) {
    width: calc(100vw - 40px);

  }

  img {


    @media (max-width: 414px) {
        width: 100%;
    }
  }

  div.back { 
    font-size: 1.2em;
    line-height: 1.6;
    color: white;
    background-color: black;
    border: 1px solid #3F3F3F;
    padding: 15px;

    @media (max-width: 414px) {
      font-size: 0.9em;
    }

    a {
      text-decoration: underline;
      text-decoration-thickness: 0.2rem;
    }

    b{
      font-variation-settings: "wght" 400;

    }
  }

  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

class SocialCapitalPart extends Component {
  constructor(props){
    super(props);
    this.state = {
      step: 0,
      scrollLabelzIndex: 11,
      touchInteractiveEnabled: false
    }

    this.zSetIntervalId = null;
  }

  initZIndex() {
    this.zSetIntervalId = setInterval(() => {
      this.setState({
        scrollLabelzIndex: Math.random() > 0.5 ? 11 : 12
      });
    }, 100)
  }
  componentWillUnmount(){
    clearInterval(this.zSetIntervalId);
  }

  handleTouchInteractiveEnabled(e){
    console.log(this.state.touchInteractiveEnabled);
    this.setState({
      touchInteractiveEnabled: !this.state.touchInteractiveEnabled
    })
  }

  componentDidMount(){
    this.initZIndex();
    // instantiate the scrollama
    const scroller = scrollama();

    // setup the instance, pass callback functions
    scroller
      .setup({
        step: ".social-capital-step",
        progress: true,
        offset: 0.9
      })
      .onStepEnter(response => {
        this.props.dispatch(changeCurrentOrgId(null));
        this.props.dispatch(changeCurrentArtistId(null));

        this.setState({
          step: response.index
        });
      })

      .onStepExit(response => {
        // console.log(response);
        let finalStep = response.index;

        if (response.direction === "up") {
          finalStep = finalStep - 1;

          if (finalStep < 0) {
            finalStep = 0;
          }
          
          this.props.dispatch(changeCurrentOrgId(null));
          this.props.dispatch(changeCurrentArtistId(null));

          this.setState({
            step: finalStep
          });

        }
      })

    // setup resize event
    window.addEventListener("resize", scroller.resize);
  }

  render() {
    let { step } = this.state;
    let { windowHeight } = this.props;
    return (
      <Fragment>
        <a name="social-capital-part"></a>
        <DescContainer>
          <Gutter h={50} />
          <DescTitle>
            사회적 자본<br/>
            Social Capital
          </DescTitle>
          <DescBigQuote>
            “청계천 을지로는 하나의 커다란 집단 지성이다. 이들은 평소에 어려운 작업에 대해 서로 물어보고 함께 해결하며 서로 일감을 나눠준다. 실제로 공업사들과 유통상사들의 거래 내역 절반 정도는 청계천-을지로 내부에서 이뤄진다. 이는 다른 공업지역에서 볼 수 없는 특이한 공동작업 방식이다.”
          </DescBigQuote>

          <Gutter h={50} />
          <CoverImg src={industry_network_photo} alt="을지로에서 작업중이신 사장님들의 사진" />
          <Gutter h={50} />
          <DescParagraph>
            이들의 사회적 관계망은 공동작업에서 그치는 것이 아니라 함께 밥먹고 차 마시기, 공식적 비공식적 행사 함께하기, 스포츠 동호회 함께 하기, 산업용재협회 활동하기, 재개발 반대 운동 참여 등을 통해 복잡한 관계망을 형성하고 있다. 이들의 사회적 자본은 호혜성을 기반으로하는 동시에 다른 지역에 비해 까다로운 작업을 빠르고 저렴하게 제작할 수 있는 기반이 되고 있다.
          </DescParagraph>
          <DescParagraph>
            미국의 정치학자 로버트 퍼트넘(Robert Putnam)은 이러한 사회적 자본을 “공동의 이익을 위해 협력과 협동을 촉진하는 신뢰, 규범이며, 네트워크와 같은 사회의 조직적 특성”이라고 정의한다. 사회적 자본은 신뢰, 사회적 규범, 지역 네트워크, 자발적 참여와 같은 형태로 가시화된다. 
          </DescParagraph>
          <DescParagraph>
            청계천과 을지로 일대 상공업은 고숙련된 기술 공정 간 서로 연계하는 협업적 생산 방식을 통해 발전해 왔다. 이는 한정된 조건 속에서 다양한 제품 주문에 대응하면서 축적된 숙련기술과, 자신만의 기술 특수성을 개발하여 오랜 기간 동안 거래하면서 형성된 생산 체계다. 자원 조달과 정보 접근이 편리한 도심에 위치해 있다는 사실은 청계천과 을지로 일대의 생산 네트워크가 외부의 다양한 거래업체들 혹은 이용자들과 활발하게 연결되고 확장될 수 있는 핵심적인 이유 중에 하나다. “청계천에 오면 무엇이든 만들 수 있다”는 말은 실체가 없는 철 지난 소문이 아니라, <a href="http://www.ohmynews.com/NWS_Web/View/at_pg.aspx?CNTN_CD=A0002608161" target="_blank" rel="noopener noreferrer">바로 이와 같은 특징을 기반으로 제조 수요에 유연하게 대처하고 있는 청계천의 생산 방식에 근거한 이야기다.</a>
          </DescParagraph>

          <DescParagraph>
            이들은 보통 10-20대에 청계천에서 일을 배우기 시작하여 30년 이상을 함께해 온 친구이자 동료이다. 이들은 공식적 업무를 함께 보는 것을 너머 함께 축구동호회, 산악회 등을 함께 하기도 하고 동년배 모임을 하기도 한다. 사회적 자본은 결속형, 수직형, 브릿지형으로도 나누는데, 이들의 사회적 자본은 결속형인 동시에 여러 장소를 이어주는 브릿지형으로도 볼 수 있다. 여러 층위의 사회적자본은 이 장소의 경쟁력이자, 청계천-을지로 생산방식을 설명하는 중요한 정보이다.
          </DescParagraph>
        </DescContainer>

        <Gutter h={5} />

        <StickyContainer style={{height: windowHeight * 11.5}}>
          <SocialCapitalMapContainer step={step} touchInteractiveEnabled={this.state.touchInteractiveEnabled} handleTouchInteractiveEnabled={this.handleTouchInteractiveEnabled.bind(this)} />

          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 1}}>
            <div className="back">
              이 지도는 청계천-을지로 일대의 필지와 도로들을 보여준다. 주로 차가 지나다닐 수 있는 너비의 길들만이 표시되어 있고 법적으로 명칭이 부여되어 있다.
            </div>
          </ScrollLabel>

          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 2}}>
            <div className="back">
              <b>입정동의 속골목</b>
              <Gutter h={10} />
              하지만 청계천-을지로의 블럭 안을 들어가보면 이 지도에 표시되어 있지 않은 많은 길을 만나게 된다. 이 길들은 차량 통행이 어려울 정도로 비좁기도 하고 사유지 안으로 나있어서 따로 길의 명칭이 없는 경우가 많다. 하지만, 이 길들은 대다수의 상인들이 일을 하면서 더 활발하게 이용하는 길이다. 이들은 필요에 따라 창고 혹은 작업장으로 쓴다. 이러한 골목길은 사회적 자본을 가시화 하는 물리적 요소로서 작용하며, 실제로 많은 <b>비공식적 네트워크</b>가 생기게 하는 기반이 된다.
              <Gutter h={10} />
              <Caveat>
                * 파란 속골목을 클릭하면 360도 카메라 촬영된 골목을 볼 수 있다.
              </Caveat>
            </div>
          </ScrollLabel>


          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 3.5}}>
            <div className="back">
              <b>비공식 조직 네트워크: 청계축구동우회</b>
              <Gutter h={10} />
              1994년 시작된 조직으로 현재 약 40명의 회원으로 구성되어 있다. 매달 1회의 축구모임과 계절별로 야유회를 간다. 입정동에서 가장 규모있는 조직답게 정관도 있고 매년 정기총회를 개최한다. 회원명부에는 신체치수(키, 발사이즈 등)까지 기입되어 있는 점이 재밌다. 현재 회장을 맡고 있는 대진정밀 사장님의 가게 입구에는 조직명패가 붙어있다. <br />
              <Gutter h={10} />
              <Carousel dynamicHeight={true} showThumbs={false}>

                <LabelImg src={soccer_image_01} alt="대진정밀에 붙어있는 청계축구동우회 명패" />
                <LabelImg src={soccer_image_02} alt="청계축구동우회 명패" />

              </Carousel>

            </div>
          </ScrollLabel>

          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 5.5}}>
            <div className="back">
              <b>비공식 네트워크: 산림동상공인연합회</b>
              <Gutter h={10} />
              세운 5-1/3구역 도시환경정비사업으로 인한 문제에 대응하기 위해 2020년 상반기에 해당 구역 내 기술자 및 상인들로 조직되었다. 매주 회의를 운영하며, 재개발반대 집회와 활동에도 적극적으로 참여하고 있다. 때때로 단합을 위해 동네잔치를 열기도 한다.
              <Gutter h={10} />
              <Carousel dynamicHeight={true} showThumbs={false}>
                <LabelImg src={sanlim_01} alt="산림동상공인연합회 뒷풀이" />
                <LabelImg src={sanlim_02} alt="산림동상공인연합회 시위모습" />
              </Carousel>

            </div>
          </ScrollLabel>
          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 7.5}}>
            <div className="back">
              
              이외에도 청계천-을지로 내에는 조직이라고 칭하기 애매한 규모의 비공식적인 모임과 행사가 많다. 이러한 관계는 영업적인 이익을 위해 만들어지기도 하지만, 오랜 세월 같이 작업하며 생긴 친밀감을 기반으로 형성되기도 한다. <br/><br/>
              다양한 비공식적 조직과 행사를 <b>좌측 상단 메뉴</b>를 통해 살펴볼 수 있다.
            </div>
          </ScrollLabel>
          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 8.5}}>
            <div className="back">
              <b>예술가들의 청계천-을지로 이용 패턴</b>
              <Gutter h={10} />
              또 한 가지 주목할 만한 사회적 자본은 예술가들의 청계천-을지로 이용 패턴이다. 현재 지도는 예술가들이 작품 제작을 위해 거래하는 업체들과 이동경로를 표시한 것이다.<br/><br/>
              대부분의 예술가들은 청계천-을지로에서 무엇을 만들어 본 경험이 있다. 주로 학부시절에 지인의 소개로 와서 과제물을 제작한다. 재료를 구매하고 기술자들에게 작업을 의뢰하는 것 외에도 이 곳에서 지역상공인과 교류하고 재료나 기술노하우에 대해 학습한다. 또한, 지역의 경관 및 분위기를 체험하고 작업의 영감을 받기도 한다.
            </div>
          </ScrollLabel>
          <ScrollLabel className="social-capital-step" style={{display: this.state.touchInteractiveEnabled ? "none" : "block", zIndex: this.state.scrollLabelzIndex, top: windowHeight * 10.5}}>
            <div className="back">
              <b>좌측 상단 메뉴</b>를 통해 개별 예술가들의 이용업체 패턴을 이동 경로를 통해 살펴볼 수 있다.
              <Gutter h={20}/>
              <Caveat>* 본 시각화 작업은 이용업체 데이터를 최단경로로 표시한 것으로 실제 예술가의 이동경로와 다를 수 있음.</Caveat>
            </div>
          </ScrollLabel>


        </StickyContainer>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowHeight: state.windowHeight
  }
}

export default connect(mapStateToProps)(SocialCapitalPart);