import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { changeLightBoxPhoto } from '../actions';
import LazyLoad from "react-lazyload";

const FullscreenPhotoContainer = styled.div`
  position: fixed;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  left: 0;
  top: 0;

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  

  img {
    height: 100%;
    width: 100%;
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
    object-fit: contain;
  }
`;

class LightBox extends Component {
  handleClose(e){
    this.props.dispatch(changeLightBoxPhoto(null));
  }
  render() {
    let { lightBoxPhoto } = this.props;
    return (
      <FullscreenPhotoContainer  style={{ height: window.innerHeight }} onClick={this.handleClose.bind(this)}>
        <img src={lightBoxPhoto.large} alt="image" />
      </FullscreenPhotoContainer>
    )
  }
}


let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    lightBoxPhoto: state.lightBoxPhoto
  }
}

export default connect(mapStateToProps)(LightBox);